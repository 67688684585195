// import node-modules ----------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import images ----------------------------------------------------------------
// import {
//     AboutUsHeaderImageLg,
//     AboutUsHeaderImageMd,
//     AboutUsHeaderImageSm
// } from '../../assets/Assets'
import { 
    AboutUsHeaderImageLg,
    AboutUsHeaderImageMd,
    AboutUsHeaderImageSm,
    AboutUsHeaderTitleText,
    AboutUsHeaderSubTitleText
} from '../../assetsv1/Assets'
import DropRightMotion from '../utils/motioncomponents/DropRightMotion'

// create the hero-banner section of about-us page ------------------------------
export default function Banner(){
    
    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToLaptop = useMediaQuery('(max-width: 960px) and (orientation: portrait)')
    // const isMatchToTablet = useMediaQuery('(max-width: 760px) and (orientation: portrait)')
    // const isMatchToMobile = useMediaQuery('(max-width: 415px) and (orientation: portrait)')
    // const isMatchToMobileLg = useMediaQuery('(max-width: 580px) and (orientation: portrait)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        position: 'relative', 
        display: 'flex', 
        width: '100vw', 
        height: 'auto',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    }
    const textContainerStyle = {
        position: 'absolute',
        display:'flex', 
        flexDirection: 'column',
        color: '#fff', 
        width: isMatchToLaptop? '50%' :'40%', 
        height: '100%',
        alignItems: 'flex-start',
        // justifyContent: 'center',
        // fontFamily: 'prompt',
        // pl: '10vw',
        pl: '5vw',
        pt: '40vh',
        // background: 'linear-gradient(to right, #232C60, transparent)',
        // transition: 'background 1s ease'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <img src={isMatchToSmallDevice? AboutUsHeaderImageSm : isMatchToLaptop? AboutUsHeaderImageMd : AboutUsHeaderImageLg} alt='About Us' style={{width: '100vw', height:'100vh'}}/>
                {/* <img 
                    src={AboutUsHeaderImageLg} 
                    alt='About Us' 
                    style={{
                        height: '100vh',
                        width: '100vw'
                    }}
                /> */}
                <Box sx={textContainerStyle}> 
                    <DropRightMotion delayTime={0.5}>
                        <img 
                            src={AboutUsHeaderTitleText}
                            alt='About Us'
                            style={{width: isMatchToSmallDevice ? '45vw' : isMatchToLaptop ? '35vw' : '25vw'}}
                        />
                    </DropRightMotion>
                    <DropRightMotion delayTime={0.5}>
                        <img 
                            src={AboutUsHeaderSubTitleText}
                            alt='Discover The Passion, People And Purpose Behind'
                            style={{width: isMatchToSmallDevice ? '38vw' : isMatchToLaptop ? '28vw' : '20vw'}}
                        />
                    </DropRightMotion>
                </Box>
            </Box>
        </>
    )
}
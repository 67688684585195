// import node modules ----------------------------------------------------------
import React, { useState } from "react"
import { useMediaQuery } from "@mui/material"

// import images ----------------------------------------------------------------
import {
    HySpecAgriculture,
    HySpecEnvironmentalMonitoring,
    HySpecMineralIdentification,
    HySpecMilitary,
    HySpecAgricultureSquare,
    HySpecEnvironmentalMonitoringSquare,
    HySpecMineralIdentificationSquare,
    HySpecMilitarySquare,
    ArrowBackwardWhiteFilled,
    ArrowBackwardWhiteOutlined,
    ArrowForwardWhiteFilled,
    ArrowForwardWhiteOutlined,
} from '../../../assetsv1/Assets'
import './HySpecApplications.css'

// create list of images --------------------------------------------------------
const HySpecApplicationList = [
    {
        image: HySpecAgriculture,
        squareImage: HySpecAgricultureSquare,
        headText: 'AGRICULTURE',
        Description: [
            {
                mainText: 'Analyse soil health and crop health',
                subText: [],
            },
            {
                mainText: 'Identify disease or pest infestation',
                subText: [],
            },
            {
                mainText: 'Classify crop types and monitor their growth stages',
                subText: [],
            },
            {
                mainText: 'Assess water stress in plants',
                subText: [],
            },
        ]
    },
    {
        image: HySpecEnvironmentalMonitoring,
        squareImage: HySpecEnvironmentalMonitoringSquare,
        headText: 'ENVIRONMENTAL MONITORING', // corrected spelling of environment on 19 Feb 2024
        Description: [
            {
                mainText: 'Assess forest health, detect invasive species, and monitor biodiversity',
                subText: [],  
            },
            {
                mainText: 'Monitor water bodies for pollutants, algal blooms, and sediment levels',
                subText: [],
            },
            {
                mainText: 'Detect and analyse atmospheric gases and pollutants',
                subText: [],
            },
        ]
    },
    {
        image: HySpecMineralIdentification,
        squareImage: HySpecMineralIdentificationSquare,
        headText: 'MINERAL IDENTIFICATION',
        Description: [
            {
                mainText: 'Identify minerals based on their spectral signatures',
                subText: [],
            },
            {
                mainText: 'Assess the environmental impact of mining activities',
                subText: [],
            },
            {
                mainText: 'Detect hazardous conditions in mines',
                subText: [],
            },
        ]
    },
    {
        image: HySpecMilitary,
        squareImage: HySpecMilitarySquare,
        headText: 'MILITARY',
        Description: [
            {
                mainText: 'Get detailed information about terrain and potential camouflage',
                subText: [],
            },
            {
                mainText: 'Identify targets by detecting spectral signatures that are not visible to the naked eye or standard cameras',
                subText: [],
            },
            {
                mainText: 'Detect and identify chemical agents from a safe distance',
                subText: [],
            },
        ]
    },
]

// create carousel --------------------------------------------------------------
export default function HySpecApplications(){
    // variables ----------------------------------------------------------------
    const [activeIndex, setActiveIndex] = useState(0)
    const isMatchToTablet = useMediaQuery('(max-width: 640px)')
    const isMatchToTabletMd = useMediaQuery('(max-width: 960px)')
    const isMatchToTabletLg = useMediaQuery('(max-width: 1220px)')
    const [isPrevBtnHovered, setIsPrevBtnHovered] = useState(false)
    const [isNextBtnHovered, setIsNextBtnHovered] = useState(false)
    // const [isDragging, setIsDragging] = useState(false)

    const [startTouchX, setStartTouchX] = useState(0)
    const [deltaX, setDeltaX] = useState(0)
    const [isSliding, setIsSliding] = useState(false)
    // const hyspecCarousel1 = document.querySelector('.lg-child-1')
    // const hyspecCarousel = document.querySelector('.lg-child')
    // style --------------------------------------------------------------------

    // functions ----------------------------------------------------------------
    // handle button clicks
    function handlePrevSlide(){
        setActiveIndex(activeIndex === 0 ? ((isMatchToTablet? HySpecApplicationList.length : 2)-1) : (activeIndex-1))
    }
    function handleNextSlide(){
        setActiveIndex(activeIndex === ((isMatchToTablet? HySpecApplicationList.length : 2) - 1) ? 0 : (activeIndex+1))
    }
    // function handleDragging(e){
    //     if(isDragging){
    //         hyspecCarousel1.scrollLeft = e.pageX
    //         hyspecCarousel.scrollLeft = e.pageX
    //     }
    // }
    function handleTouchStart(e){
        setIsSliding(true)
        setStartTouchX(e.touches[0].pageX)
    }
    function handleTouchCancel(e){
        setIsSliding(false)
        setStartTouchX(null)
    }
    function handleTouchEnd(e){
        if (deltaX < 0){
            setActiveIndex(activeIndex === (HySpecApplicationList.length - 1) ? 0 : activeIndex+1)
        }else if (deltaX > 0){
            setActiveIndex(activeIndex === 0 ? (HySpecApplicationList.length - 1) : activeIndex-1)
        }else{
            setActiveIndex(activeIndex)
        } 
        setIsSliding(false)
        setStartTouchX(null)
    }
    function handleTouchMove(e){
        if(startTouchX !== null && isSliding === true){
            const currentX = e.touches[0].pageX
            const deltaX = currentX - startTouchX
            setDeltaX(deltaX)
        }
    }
    // main layout --------------------------------------------------------------
    return(
        <>
            <div className="hyspec-carousel-outer-div">
                <div className="hyspec-carousel-heading-container">
                    <p className="hyspec-carousel-heading">
                        Hyperspectral applications
                    </p>
                </div>
                <div className="hyspec-carousel-inner-div">
                    <div 
                        className="hyspec-carousel-prev-btn-container" 
                        onTouchStart={()=>{setIsPrevBtnHovered(true)}}
                        onTouchEnd={()=>{setIsPrevBtnHovered(false)}}
                        onMouseEnter={()=>{setIsPrevBtnHovered(true)}}
                        onMouseLeave={()=>{setIsPrevBtnHovered(false)}}
                        onClick={()=>{handlePrevSlide()}}
                    >
                        <img src={isPrevBtnHovered?ArrowBackwardWhiteOutlined : ArrowBackwardWhiteFilled} alt="hyspec-prev" className="hyspec-prev-btn"/>
                    </div>
                    {
                        isMatchToTablet ? (
                            <div 
                                className="hyspec-carousel-div-1"
                                onTouchStart={(e)=>{handleTouchStart(e)}}
                                onTouchMove={(e)=>{handleTouchMove(e)}}
                                onTouchEnd={(e)=>{handleTouchEnd(e)}}
                                onTouchCancel={(e)=>{handleTouchCancel(e)}}
                            >
                                <div 
                                    // onMouseMove={()=>{handleDragging()}}
                                    // onMouseDown={()=>{setIsDragging(true); hyspecCarousel1.classList.add('dragging')}}
                                    // onMouseUp={()=>{setIsDragging(false)}}
                                    className="hyspec-lg-child-1" 
                                    style={{translate: `${activeIndex * (-100)}%`}}
                                >
                                    {
                                        HySpecApplicationList.map((item, index)=>(
                                            <div key={index} className="hyspec-application-1" >
                                                <img src={item.squareImage} alt={item.squareImage} className="hyspec-application-img-1"/>
                                                {/* <p className="hyspec-application-title-1">{item.headText}</p> */}
                                                <div className="hyspec-text-overlay-1">
                                                    <p className="hyspec-text-overlay-heading-1">
                                                        {item.headText}
                                                    </p>
                                                    {/* <p className="hyspec-text-overlay-description-1">
                                                        {item.Description[0].mainText}
                                                    </p> */}
                                                    <ul className="hyspec-text-overlay-description-1">
                                                        {
                                                            item.Description.map((text, idx)=>(
                                                                <li key={idx}>
                                                                    {text.mainText}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ) : (
                            isMatchToTabletLg ? (
                                <div className="hyspec-carousel-div">
                                    <div className="hyspec-lg-child" id="hyspec-child-1" style={{translate: `${activeIndex * (-100)}%`}}>
                                        {
                                            HySpecApplicationList.slice(0, 2).map((item, index)=>(
                                                <div key={index} className="hyspec-application" >
                                                    <img src={isMatchToTabletMd ? item.squareImage : item.image} alt={item.image} className="hyspec-application-img"/>
                                                    <p className="hyspec-application-title">{item.headText}</p>
                                                    <div className="hyspec-text-overlay">
                                                        <p className="hyspec-text-overlay-heading">
                                                            {item.headText}
                                                        </p>
                                                        {/* <p className="hyspec-text-overlay-description">
                                                            {item.Description[0].mainText}
                                                        </p> */}
                                                        <ul className="hyspec-text-overlay-description">
                                                            {
                                                                item.Description.map((text, idx)=>(
                                                                    <li key={idx}>
                                                                        {text.mainText}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="hyspec-lg-child" id="hyspec-child-2" style={{translate: `${activeIndex * (-100)}%`}}>
                                        {
                                            HySpecApplicationList.slice(2, 4).map((item, index)=>(
                                                <div key={index} className="hyspec-application" >
                                                    <img src={isMatchToTabletMd ? item.squareImage : item.image} alt={item.image} className="hyspec-application-img"/>
                                                    <p className="hyspec-application-title">{item.headText}</p>
                                                    <div className="hyspec-text-overlay">
                                                        <p className="hyspec-text-overlay-heading">
                                                            {item.headText}
                                                        </p>
                                                        {/* <p className="hyspec-text-overlay-description">
                                                            {item.Description[0].mainText}
                                                        </p> */}
                                                        <ul className="hyspec-text-overlay-description">
                                                            {
                                                                item.Description.map((text, idx)=>(
                                                                    <li key={idx}>
                                                                        {text.mainText}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div className="hyspec-carousel-div">
                                    <div className="hyspec-lg-child" id="hyspec-child-1">
                                        {
                                            HySpecApplicationList.map((item, index)=>(
                                                <div key={index} className="hyspec-application" >
                                                    <img src={item.image} alt={item.image} className="hyspec-application-img"/>
                                                    <p className="hyspec-application-title">{item.headText}</p>
                                                    <div className="hyspec-text-overlay">
                                                        <p className="hyspec-text-overlay-heading">
                                                            {item.headText}
                                                        </p>
                                                        {/* <p className="hyspec-text-overlay-description">
                                                            {item.Description[0].mainText}
                                                        </p> */}
                                                        <ul className="hyspec-text-overlay-description">
                                                            {
                                                                item.Description.map((text, idx)=>(
                                                                    <li key={idx}>
                                                                        {text.mainText}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                            
                        )
                    }
                    <div
                        className="sar-carousel-next-btn-container" 
                        onTouchStart={()=>{setIsNextBtnHovered(true)}}
                        onTouchEnd={()=>{setIsNextBtnHovered(false)}}
                        onMouseEnter={()=>{setIsNextBtnHovered(true)}}
                        onMouseLeave={()=>{setIsNextBtnHovered(false)}} 
                        onClick={()=>{handleNextSlide()}}
                    >
                        <img src={isNextBtnHovered ? ArrowForwardWhiteOutlined : ArrowForwardWhiteFilled} alt="sar-next" className="sar-next-btn"/>
                    </div>
                </div>
            </div>
        </>
    )
}

// import node-modules ----------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery,
    Modal
} from '@mui/material'
import { useState } from 'react'


// import cross-icon image ------------------------------------------------------
// import {
//     CrossNormal,
//     CrossBold
// } from '../../assets/Assets'
import { CrossNormal, CrossBold } from '../../assetsv1/Assets'

// css --------------------------------------------------------------------------
import '../../index.css'

// custom modal -----------------------------------------------------------------
export default function CustomModal({personImage, name, designation, description, closeModal, setCloseModal}){

    //  const theme and media-query ---------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToMdLaptop4 = useMediaQuery('(max-width: 1560px)')
    const isMatchToMdLaptop = useMediaQuery('(max-width: 1440px)')
    const isMatchToMdLaptop2 = useMediaQuery('(max-width: 1200px)')
    const isMatchToMdLaptop3 = useMediaQuery('(max-width: 1024px)')
    const isMatchToTablet1 = useMediaQuery('(max-width: 900px) ')
    const isMatchToTablet2 = useMediaQuery('(max-width: 800px)')
    const isMatchToTablet3 = useMediaQuery('(max-width: 720px)')
    const isMatchToTablet4 = useMediaQuery('(max-width: 600px)')
    const isMatchToMobile1 = useMediaQuery('(max-width: 550px)')
    const isMatchToHeight1 = useMediaQuery('(min-height: 600px)')
    const isPortrait = useMediaQuery('(orientation: portrait)')

    // state variables ----------------------------------------------------------
    const [crossHovered, setCrossHovered] = useState(false)

    // style --------------------------------------------------------------------
    const crossIconContainerStyle = {
        display: 'flex', 
        flexDirection: 'column', 
        alignSelf: 'flex-end', 
        justifySelf: 'flex-start',
        // pt: isMatchToMobile1 & isPortrait ?5:isMatchToSmallDevice &isPortrait? 5: 1,
        pt: 1,
        pr: 1
    }
    const infoContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        gap: '4px',
        marginBottom: '4px'
    }
    const mainModalContentBoxStyle = {
        display: 'flex',
        margin: 2,
        justifyContent: 'space-around',
        flexFlow: isMatchToMobile1? 'no-wrap' : 'row wrap',
        width: 'fit-content',
        // gap: isMatchToTablet4? '16px' : isMatchToTablet2? '24px' : '52px',
        flexDirection: isMatchToMobile1? 'column' : 'row'
        
    }
    const flexMainContainerStyle = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        // gap: '16px',
        height: isMatchToMobile1? '100vh' : isMatchToHeight1? '490px' : 'fit-content',
        // maxHeight: '80vh',
        backgroundColor: '#fff',
        color: '#232C60',
        borderRadius: '10px',
        flexShrink: isMatchToSmallDevice? '1' : '0', 
        // overflow: 'scroll',
        width: isMatchToMobile1? '100vw' : isMatchToTablet1? '90vw' : isMatchToMdLaptop3? '86vw' : isMatchToMdLaptop2? '76vw' : isMatchToMdLaptop? '65vw' : isMatchToMdLaptop4? '55vw' : '50vw',
        // maxWidth: '980px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    }
    const imageContainerStyle = {
        display: 'flex',
        alignSelf: 'flex-start'
        
    }
    const detailInfoContainerStyle = {
        display: 'flex',
        // overflow: 'auto',
        width: isMatchToMobile1? '80vw' : isMatchToTablet3? '47vw' : isMatchToTablet1? '50%' : '50%',
        // height: isMatchToMdLaptop3? '245px' : '400pxpx',
        maxHeight: isMatchToMobile1 & !isPortrait? '60vh': isMatchToTablet1 & isPortrait? '35vh': isMatchToTablet1?'60vh':'40vh',
        // alignSelf: 'center',
        // margin: 1,
        pb: isMatchToMobile1? 2 : 0,
    }
    const imageAndInfoContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // margin: 1,
        gap: '10px',
        flexShrink: 0,
        width: isMatchToMobile1? '256px' : isMatchToTablet4 ? '200px' : isMatchToTablet2? '220px' : '256px',
        alignSelf: isMatchToMobile1? 'center' : 'flex-start',
        justifySelf: isMatchToMobile1? 'center' : 'flex-start',
        // maxHeight: '50vh',
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Modal open={!closeModal} onClose={()=>setCloseModal(true)}>
                <Box sx={flexMainContainerStyle}>
                    <Box sx={crossIconContainerStyle}>
                        <img 
                            src={crossHovered? CrossBold : CrossNormal} 
                            alt='exit' 
                            onMouseEnter={()=>setCrossHovered(true)}
                            onMouseLeave={()=>setCrossHovered(false)}
                            onClick={()=>setCloseModal(true)}
                            height={isMatchToSmallDevice ? '24px' :'24px'}
                        />
                    </Box>
                    <Box sx={mainModalContentBoxStyle}>
                        <Box sx={imageAndInfoContainerStyle}>
                            <Box sx={imageContainerStyle}>
                                <img src={personImage} alt={name} style={{width: '100%', maxHeight: '40vh'}}/>
                            </Box>
                            <Box sx={infoContainerStyle}>
                                <Box sx={{fontFamily: 'Oxanium-Bold', fontSize: isMatchToSmallDevice ? '16px' : '18px'}}>
                                    {name}
                                </Box>
                                <Box sx={{fontFamily: 'NunitoSans_7pt-Regular', fontSize: isMatchToSmallDevice ? '14px' : '16px'}}>
                                    {designation}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={detailInfoContainerStyle}>
                            <Box 
                                className= 'modal-scrollable-content'
                                sx={{
                                    display: 'flex', 
                                    flexDirection: 'column',
                                    overflowY: isMatchToTablet1? 'scroll':'auto',
                                    gap: '16px',
                                    pl: 2,
                                    pr: 2,
                                    fontFamily: 'NunitoSans_7pt-Regular',
                                    fontSize: isMatchToSmallDevice ? '12px' : '14px'
                                }}
                            >
                                {
                                    description.map((descriptionText, index)=>(
                                        <Box key={index} >
                                            {
                                                descriptionText.mainText && 
                                                <>
                                                    {descriptionText.mainText}
                                                </>
                                            }
                                            
                                            {
                                                descriptionText.subText &&
                                                <ul>
                                                    {
                                                        descriptionText.subText.map((subText, subIndex)=>(
                                                            <li key={subIndex}>
                                                                {subText}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
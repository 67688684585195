// import node modules ----------------------------------------------------------
import { useEffect } from "react"
import {Helmet} from 'react-helmet-async'


// import created modules -------------------------------------------------------
import Banner from "../hyspeccomponents/HyspecBanner"
import WhatIsHyspec from "../hyspeccomponents/WhatIsHyperspectral"
import FeaturesOfHySpec from "../hyspeccomponents/FeaturesOfHySpec"
import AdvantagesOfDroneBorneHySpecImaging from "../hyspeccomponents/AdvantagesOfDroneBorneHySpecImaging"
import HySpecApplications from "../hyspeccomponents/hyspecapplications/HySpecApplications"
import Brochures from "../hyspeccomponents/brochures/Brochures"

// import images ----------------------------------------------------------------
import { FavIcon } from '../../assetsv1/Assets'

// create gpr page -------------------------------------------------------------
export default function HySpec(){

    // scroll vertically to zero on load ----------------------------------------
    useEffect(() => {
        window.scrollTo(0,0)
      }, []);

    return(
        <>
            <Helmet>
                <title>Hypespectral | Technology | Sisir Radar</title>
                <meta name='description'content='Get a better understanding of Hyperspectral Imaging and its various military and civilian applications.' data-rh='true' />
                <link rel='cannonical' href='/technology/hyspec'/>
                <link rel="icon" href={FavIcon} />
            </Helmet>
            <Banner/>
            <WhatIsHyspec/>
            <FeaturesOfHySpec/>
            <AdvantagesOfDroneBorneHySpecImaging/>
            <HySpecApplications/>
            <Brochures/>
        </>
    )
}

// import node modules ----------------------------------------------------------
import React, { useState } from "react"
import { useMediaQuery } from "@mui/material"

// import images ----------------------------------------------------------------
import {
    Agriculture,
    Military,
    Mining,
    TownPlanning,
    AgricultureSquare,
    MilitarySquare,
    MiningSquare,
    TownPlanningSquare,
    ArrowBackwardWhiteFilled,
    ArrowBackwardWhiteOutlined,
    ArrowForwardWhiteFilled,
    ArrowForwardWhiteOutlined,
} from '../../../assetsv1/Assets'
import './SARApplications.css'

// create list of images --------------------------------------------------------
const SARApplicationList = [
    {
        image: Agriculture,
        squareImage: AgricultureSquare,
        headText: 'AGRICULTURE',
        Description: [
            {
                mainText: 'Crop distribution planning',
                subText: [],
            },
            {
                mainText: 'Ground fertility & moisture assessment',
                subText: [],
            },
            {
                mainText: 'Yield prediction',
                subText: [],
            },
            {
                mainText: 'Accurate crop damage assessment',
                subText: [],
            },
            {
                mainText: 'Precise insurance payout calculation',
                subText: [],
            }
        ]
    },
    {
        image: Military,
        squareImage: MilitarySquare,
        headText: 'MILITARY',
        Description: [
            {
                mainText: 'Low altitude drones escape radar detection',
                subText: [],  
            },
            {
                mainText: 'SAR’s side-looking imaging can be done almost 15 km away from the target area',
                subText: [],
            },
            {
                mainText: 'SAR drones can fly deep inside home territory and effectively map enemy positions, all while staying beyond the reach of enemy artillery',
                subText: [],
            }
        ]
    },
    {
        image: Mining,
        squareImage: MiningSquare,
        headText: 'MINING',
        Description: [
            {
                mainText: 'Open cast mining volume identification at low cost with minimal operational overhead',
                subText: [],
            },
            {
                mainText: 'Mineral detection',
                subText: [],
            },
            {
                mainText: 'Map geological hotspots',
                subText: [],
            },
            {
                mainText: 'Track illegal mining',
                subText: [],
            },
        ]
    },
    {
        image: TownPlanning,
        squareImage: TownPlanningSquare,
        headText: 'TOWN PLANNING',
        Description: [
            {
                mainText: 'Construction monitoring',
                subText: [],
            },
            {
                mainText: 'Best expansion direction',
                subText: [],
            },
            {
                mainText: 'Soil subsidence measurement',
                subText: [],
            },
        ]
    },
]

// create carousel --------------------------------------------------------------
export default function SARApplications(){
    // variables ----------------------------------------------------------------
    const [activeIndex, setActiveIndex] = useState(0)
    const isMatchToTablet = useMediaQuery('(max-width: 640px)')
    const isMatchToTabletMd = useMediaQuery('(max-width: 960px)')
    const isMatchToTabletLg = useMediaQuery('(max-width: 1220px)')
    const [isPrevBtnHovered, setIsPrevBtnHovered] = useState(false)
    const [isNextBtnHovered, setIsNextBtnHovered] = useState(false)

    const [startTouchX, setStartTouchX] = useState(0)
    const [deltaX, setDeltaX] = useState(0)
    const [isSliding, setIsSliding] = useState(false)

    // const [isDragging, setIsDragging] = useState(false)
    // const sarCarousel1 = document.querySelector('.lg-child-1')
    // const sarCarousel = document.querySelector('.lg-child')
    // style --------------------------------------------------------------------

    // functions ----------------------------------------------------------------
    // handle button clicks
    function handlePrevSlide(){
        setActiveIndex(activeIndex === 0 ? ((isMatchToTablet? SARApplicationList.length : 2)-1) : (activeIndex-1))
    }
    function handleNextSlide(){
        setActiveIndex(activeIndex === ((isMatchToTablet? SARApplicationList.length : 2) - 1) ? 0 : (activeIndex+1))
    }
    // function handleDragging(e){
    //     if(isDragging){
    //         sarCarousel1.scrollLeft = e.pageX
    //         sarCarousel.scrollLeft = e.pageX
    //     }
    // }
    function handleTouchStart(e){
        setIsSliding(true)
        setStartTouchX(e.touches[0].pageX)
    }
    function handleTouchCancel(e){
        setIsSliding(false)
        setStartTouchX(null)
    }
    function handleTouchEnd(e){
        if (deltaX < 0){
            setActiveIndex(activeIndex === (SARApplicationList.length - 1) ? 0 : activeIndex+1)
        }else if (deltaX > 0){
            setActiveIndex(activeIndex === 0 ? (SARApplicationList.length - 1) : activeIndex-1)
        }else{
            setActiveIndex(activeIndex)
        } 
        setIsSliding(false)
        setStartTouchX(null)
    }
    function handleTouchMove(e){
        if(startTouchX !== null && isSliding === true){
            const currentX = e.touches[0].pageX
            const deltaX = currentX - startTouchX
            setDeltaX(deltaX)
        }
    }
    // main layout --------------------------------------------------------------
    return(
        <>
            <div className="sar-carousel-outer-div">
                <div className="sar-carousel-heading-container">
                    <p className="sar-carousel-heading">
                        sar applications
                    </p>
                </div>
                <div className="sar-carousel-inner-div">
                    <div 
                        className="sar-carousel-prev-btn-container" 
                        onTouchStart={()=>{setIsPrevBtnHovered(true)}}
                        onTouchEnd={()=>{setIsPrevBtnHovered(false)}}
                        onMouseEnter={()=>{setIsPrevBtnHovered(true)}}
                        onMouseLeave={()=>{setIsPrevBtnHovered(false)}}
                        onClick={()=>{handlePrevSlide()}}
                    >
                        <img src={isPrevBtnHovered?ArrowBackwardWhiteOutlined : ArrowBackwardWhiteFilled} alt="sar-prev" className="sar-prev-btn"/>
                    </div>
                    {
                        isMatchToTablet ? (
                            <div 
                                className="sar-carousel-div-1"
                                onTouchStart={(e)=>{handleTouchStart(e)}}
                                onTouchMove={(e)=>{handleTouchMove(e)}}
                                onTouchEnd={(e)=>{handleTouchEnd(e)}}
                                onTouchCancel={(e)=>{handleTouchCancel(e)}}
                            >
                                <div 
                                    className="sar-lg-child-1" 
                                    style={{translate: `${activeIndex * (-100)}%`}}
                                >
                                    {
                                        SARApplicationList.map((item, index)=>(
                                            <div key={index} className="sar-application-1" >
                                                <img src={item.squareImage} alt={item.squareImage} className="sar-application-img-1"/>
                                                {/* <p className="sar-application-title-1">{item.headText}</p> */}
                                                <div className="sar-text-overlay-1">
                                                    <p className="sar-text-overlay-heading-1">
                                                        {item.headText}
                                                    </p>
                                                    <ul className="sar-text-overlay-description-1">
                                                        {
                                                            item.Description.map((text, idx)=>(
                                                                <li key={idx}>
                                                                    {text.mainText}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ) : (
                            isMatchToTabletLg ? (
                                <div className="sar-carousel-div">
                                    <div className="sar-lg-child" id="sar-child-1" style={{translate: `${activeIndex * (-100)}%`}}>
                                        {
                                            SARApplicationList.slice(0, 2).map((item, index)=>(
                                                <div key={index} className="sar-application" >
                                                    <img src={isMatchToTabletMd ? item.squareImage : item.image} alt={item.image} className="sar-application-img"/>
                                                    <p className="sar-application-title">{item.headText}</p>
                                                    <div className="sar-text-overlay">
                                                        <p className="sar-text-overlay-heading">
                                                            {item.headText}
                                                        </p>
                                                        <ul className="sar-text-overlay-description">
                                                            {
                                                                item.Description.map((text, idx)=>(
                                                                    <li key={idx}>
                                                                        {text.mainText}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="sar-lg-child" id="sar-child-2" style={{translate: `${activeIndex * (-100)}%`}}>
                                        {
                                            SARApplicationList.slice(2, 4).map((item, index)=>(
                                                <div key={index} className="sar-application" >
                                                    <img src={isMatchToTabletMd ? item.squareImage : item.image} alt={item.image} className="sar-application-img"/>
                                                    <p className="sar-application-title">{item.headText}</p>
                                                    <div className="sar-text-overlay">
                                                        <p className="sar-text-overlay-heading">
                                                            {item.headText}
                                                        </p>
                                                        <ul className="sar-text-overlay-description">
                                                            {
                                                                item.Description.map((text, idx)=>(
                                                                    <li key={idx}>
                                                                        {text.mainText}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div className="sar-carousel-div">
                                    <div className="sar-lg-child" id="sar-child-1">
                                        {
                                            SARApplicationList.map((item, index)=>(
                                                <div key={index} className="sar-application" >
                                                    <img src={item.image} alt={item.image} className="sar-application-img"/>
                                                    <p className="sar-application-title">{item.headText}</p>
                                                    <div className="sar-text-overlay">
                                                        <p className="sar-text-overlay-heading">
                                                            {item.headText}
                                                        </p>
                                                        <ul className="sar-text-overlay-description">
                                                            {
                                                                item.Description.map((text, idx)=>(
                                                                    <li key={idx}>
                                                                        {text.mainText}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                            
                        )
                    }
                    <div
                        className="sar-carousel-next-btn-container" 
                        onTouchStart={()=>{setIsNextBtnHovered(true)}}
                        onTouchEnd={()=>{setIsNextBtnHovered(false)}}
                        onMouseEnter={()=>{setIsNextBtnHovered(true)}}
                        onMouseLeave={()=>{setIsNextBtnHovered(false)}} 
                        onClick={()=>{handleNextSlide()}}
                    >
                        <img src={isNextBtnHovered ? ArrowForwardWhiteOutlined : ArrowForwardWhiteFilled} alt="sar-next" className="sar-next-btn"/>
                    </div>
                </div>
            </div>
        </>
    )
}

// import created modules -------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import images ----------------------------------------------------------------
import { 
    AllWeatherSensor,
    FeatureIdentification,
    StructuralSeparation,
    DistanceResolution,
    AngleResolution,
    NoAtmosphericCorrectionNeeded,
    AreaCovered100X,
 } from '../../assetsv1/Assets'

//  import created modules ------------------------------------------------------
import CustomIconCard from './CustomIconCard'

// list-of-icons ----------------------------------------------------------------
const otherIconList = [
    {
        icon: AllWeatherSensor,
        description: ['All-weather,', 'Day-Night Sensor']
    },
    {
        icon: FeatureIdentification,
        description: ['Perfect for ML-based', 'Feature Identification']
    },
    {
        icon: StructuralSeparation,
        description: ['Can Separate', 'Structural Difference']
    },
    {
        icon: DistanceResolution,
        description: ['Resolution Independent', 'of distance']
    },
    {
        icon: AngleResolution,
        description: ['Resolution Independent', 'of Look Angle']
    },
    {
        icon: NoAtmosphericCorrectionNeeded,
        description: ['No Atmospheric', 'correction needed']
    },
    {
        icon: AreaCovered100X,
        description: ['100x More Area', 'Covered']
    }
]

// create feature-component of SAR ----------------------------------------------
export default function SARSuperiority(){

    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToMobileSm = useMediaQuery('(max-width: 375px)')
    const isMatchToMobile = useMediaQuery('(max-width: 480px)')
    const isMatchToMobileLg = useMediaQuery('(max-width: 550px)')
    const isMatchToTabletSm = useMediaQuery('(max-width: 880px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const  isMatchToTablet2 = useMediaQuery('(max-width: 1440px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        backgroundColor: '#232C60',
        width: '100vw',
        height: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        wrap: 'wrap',
    }
    const flexSubContainerStyle = {
        display: 'flex', 
        flexDirection: 'column',
        width: '92%', 
        justifyContent: 'space-around',
        alignItems: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '52px' : '48px',
    }
    const childStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: isMatchToMobile? '32px' : isMatchToMobileLg? '52px' : isMatchToTabletSm? '84px' : isMatchToTablet? '40px' : isMatchToTablet2? '52px' : isMatchToLaptop? '84px' : '100px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexFlow: 'row wrap',
    }
    const headerTextContainerStyle = {
        display: 'flex',
        color: '#fff',
        width: 'fit-content',
        alignItems: 'center',
        textAlign: 'center'
    }
    const header2TextContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        fontSize: 'clamp(16px, 1vw, 28px)',
        fontFamily: 'NunitoSans_7pt-SemiBold',
        flexFlow: 'row wrap'
    }
    const mainElementContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        color: '#fff',
        maxWidth: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    }
    const cardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        alignItems: 'center',
        justifyContent: 'center',
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}} >
                        <Box sx={headerTextContainerStyle}>
                            <p 
                                style={{fontFamily:'Oxanium-Bold', 
                                textTransform: 'uppercase', 
                                fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px',
                            }}
                            >
                                Sar superiority over hyperspectral
                            </p>
                        </Box>
                        <Box sx={header2TextContainerStyle}>
                            <Box sx={{display: 'flex', gap: '5px'}}>
                                <p style={{color: '#FF671F'}}>Made</p>
                                <p style={{color: '#fff'}}>In</p>
                                <p style={{color: '#67D90B'}}>India</p>
                            </Box>
                            <Box>
                                <p style={{color: '#fff', textAlign: isMatchToMobileSm? 'center' : '', wordSpace: '4px'}}>All-Weather Day & Night Radar System</p>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={mainElementContainerStyle}>
                        <Box sx={cardContainerStyle}>
                            {
                                isMatchToTabletSm? (
                                    <>
                                        <Box sx={childStyle}>
                                            {
                                                otherIconList.slice(0,2).map((icon, index)=>(
                                                    <CustomIconCard 
                                                        key={index}
                                                        icon={icon.icon}
                                                        description={icon.description}
                                                        iconWidth={isMatchToMobileSm? '64px' : '80px'}
                                                        cardWidth={isMatchToMobileSm? '130px' : isMatchToMobile? '150px' : '178px'}
                                                        fontSize={isMatchToMobileSm? '12px' : isMatchToMobile? '14px' : '16px'}
                                                    />
                                                ))
                                            }
                                        </Box>
                                        <Box sx={childStyle}>
                                            {
                                                otherIconList.slice(2,4).map((icon, index)=>(
                                                    <CustomIconCard 
                                                        key={index}
                                                        icon={icon.icon}
                                                        description={icon.description}
                                                        iconWidth={isMatchToMobileSm? '64px' : '80px'}
                                                        cardWidth={isMatchToMobileSm? '130px' : isMatchToMobile? '150px' : '178px'}
                                                        fontSize={isMatchToMobileSm? '12px' : isMatchToMobile? '14px' : '16px'}
                                                    />
                                                ))
                                            }
                                        </Box>
                                        <Box sx={childStyle}>
                                            {
                                                otherIconList.slice(4,6).map((icon, index)=>(
                                                    <CustomIconCard 
                                                        key={index}
                                                        icon={icon.icon}
                                                        description={icon.description}
                                                        iconWidth={isMatchToMobileSm? '64px' : '80px'}
                                                        cardWidth={isMatchToMobileSm? '130px' : isMatchToMobile? '150px' : '178px'}
                                                        fontSize={isMatchToMobileSm? '12px' : isMatchToMobile? '14px' : '16px'}
                                                    />
                                                ))
                                            }
                                        </Box>
                                        <Box sx={childStyle}>
                                            {
                                                otherIconList.slice(6,7).map((icon, index)=>(
                                                    <CustomIconCard 
                                                        key={index}
                                                        icon={icon.icon}
                                                        description={icon.description}
                                                        iconWidth={isMatchToMobileSm? '64px' : '80px'}
                                                        cardWidth={isMatchToMobileSm? '130px' : isMatchToMobile? '150px' : '178px'}
                                                        fontSize={isMatchToMobileSm? '12px' : isMatchToMobile? '14px' : '16px'}
                                                    />
                                                ))
                                            }
                                        </Box>
                                    </>
                                ):(
                                   <>
                                        <Box sx={childStyle}>
                                            {
                                                otherIconList.slice(0,4).map((icon, index)=>(
                                                    <CustomIconCard 
                                                        key={index}
                                                        icon={icon.icon}
                                                        description={icon.description}
                                                        iconWidth={isMatchToTablet2? '64px' : '80px'}
                                                        cardWidth={isMatchToTablet? '150px' : '178px'}
                                                        fontSize={isMatchToTablet? '14px' : '16px'}
                                                    />
                                                ))
                                            }
                                        </Box>
                                        <Box sx={childStyle}>
                                            {
                                                otherIconList.slice(4, 7).map((icon, index)=>(
                                                    <CustomIconCard 
                                                        key={index}
                                                        icon={icon.icon}
                                                        description={icon.description}
                                                        iconWidth={isMatchToTablet2? '64px' : '80px'}
                                                        cardWidth={isMatchToTablet? '150px' : '178px'}
                                                        fontSize={isMatchToTablet? '14px' : '16px'}
                                                    />
                                                ))
                                            }
                                        </Box>
                                   </> 
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
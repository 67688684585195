// import node modules ----------------------------------------------------------
import React from "react"

// import images ----------------------------------------------------------------
import {
    BrochureFileIcon,
    HySkyView51
} from '../../../assetsv1/Assets'
import './Brochures.css'

// create list of brochures -----------------------------------------------------
const brochureList = [
    {
        title: 'HYSKYVIEW-51',
        pdf: HySkyView51
    },
]

// create brochure section ------------------------------------------------------
export default function Brochures(){
    // variables
    // const [showPdf, setShowPdf] = useState(false)

    // styles

    // functions
    // handle show pdf in a neew tab
    const handleShowPdf = (item) => {
        // setShowPdf(true)
        window.open(item.pdf, '_blank')
    }
    // handle downlad pdf
    // const handleDownloadPdf = (item) => {
    //     const link = document.createElement('a')
    //     link.href = item.pdf
    //     link.download = `${item.title}.pdf`

    //     document.appendChild(link)
    //     link.click()
    //     document.removeChild(link)
    // }

    // main layout
    return(
        <>
            <div className="hyspec-brochure-container-outer-div">
                <h1>brochure</h1>
                <div className="hyspec-brochure-container-inner-div">
                    {
                        brochureList.map((item, index)=>(
                            <div key={index} className="hyspec-brochure-item">
                                <img src={BrochureFileIcon} alt={BrochureFileIcon}/>
                                <p onClick={()=>handleShowPdf(item)}>
                                    {item.title}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

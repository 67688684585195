// import node modules ----------------------------------------------------------
import React from 'react'
import ReactDOM from 'react-dom/client'
import {HelmetProvider} from 'react-helmet-async'
// import reportWebVitals from './reportWebVitals'

// import created modules -------------------------------------------------------
import './index.css'
import App from './App'

// get the root from index.html -------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root'))

// render complete application or website inside the root -----------------------
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
)
// reportWebVitals()

// import node modules ----------------------------------------------------------

// import images ----------------------------------------------------------------
import {
    SARSatellite,
    Aeroplane,
    SARDroneFinal
} from '../../../assetsv1/Assets'
import './SARPlatforms.css'

// create main layout
export default function SARPlatforms(){
    // variables-----------------------------------------------------------------

    // style --------------------------------------------------------------------

    // main-layout --------------------------------------------------------------
    return(
        <>
            <div className='sar-platforms-main-div'>
                <div className='sar-upper-div'>
                    <p className='sar-platform-heading'>
                        Platforms
                    </p>
                </div>
                <div className='sar-lower-div'>
                    <div className='sar-circle-container'>
                        <circle className='sar-circle' id='sar-satellite'>
                            <img src={SARSatellite} alt={SARSatellite} className='platform-image' id='sar-satellite-image'/>
                            <p className='sar-platform-name'>Satellite</p>
                        </circle>
                        <circle className='sar-circle' id='sar-aircraft'>
                            <img src={Aeroplane} alt={Aeroplane} className='platform-image' id='sar-aeroplane-image'/>
                            <p className='sar-platform-name'>aircraft</p>
                        </circle>
                        <circle className='sar-circle' id='sar-drone'>
                            <img src={SARDroneFinal} alt={SARDroneFinal} className='platform-image' id='sar-drone-image'/>
                            <p className='sar-platform-name'>drone</p>
                        </circle>
                    </div>
                </div>
                {/* <div className='circle-container'></div> */}
            </div>
        </>
    )
}

// import node modules ----------------------------------------------------------
import React from "react"
import { Box, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from 'react-router-dom'


import './WeOfferSection.css'
// import images ----------------------------------------------------------------
import { 
    SARDemoProcessedImage,
    GPRDemoProcessedImage,
    HyspecDemoProcessedImage,
} from "../../assetsv1/Assets"

// list of slidable objects
const slideList = [
    {
        image: SARDemoProcessedImage,
        title: "Synthetic Aperture Radar",
        description: "Sisir Radar offers cutting-edge Synthetic Aperture Radar systems for air-borne and space-borne platforms operating in P/L/S/C/X bands. The payload can be optimised in terms of SWaP (Size, Weight and Power) - meaning that it can be fit in Tactical UAVs, VTOL and fixed-wing UAVs as well as aircrafts."
    },
    {
        image: GPRDemoProcessedImage,
        title: "Ground Penetrating Radar",
        description: "Ruggedised Ground Penetrating Radars offered by Sisir Radar can be fitted on drones, carts, hand-held devices and other custom platforms. The GPRs have multiple applications like IED detection, mineral detection in underground mines, utility mapping and infrastructure monitoring."
    },
    {
        image: HyspecDemoProcessedImage,
        title: "Hyperspectral Imaging",
        description: "Sisir Radar’s cutting-edge drone-borne Hyperspectral Imaging System makes it possible to collect hyperspectral data cube at a high speed using the Snapshot capture technology. The proprietary software provides user-friendly data cube visualisation capabilities and converts this data into actionable insights for applications in agriculture, environmental study, and urban planning."
    },
]

// create all-things-radar function ---------------------------------------------
export default function AllThingsRadar(){
    // varibales ----------------------------------------------------------------
    // mediaquery

    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToMobileSm = useMediaQuery('(max-width: 480px)')
    // const isMatchToMobile = useMediaQuery('(max-width: 720px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    // const isMatchToLaptop = useMediaQuery('(max-width: 1200px)')

    // navigate-hook ------------------------------------------------------------
    const navigate = useNavigate()

    // style --------------------------------------------------------------------
    const flexMainContainerLayout = {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        backgroundColor: '#232C60',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        pt: '8vh',
        pb: '8vh',
        gap: '4vh',
    }
    const headingStyle = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        fontFamily: 'Oxanium-Bold',
        fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px',
        textTransform: 'uppercase'
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        width: '90%',
        justifyContent: 'center',
        marginBottom: '40px',
        marginTop: '40px',
        gap: '40px',
        alignSelf: 'center'
    }
    const detailInfoContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignSelf: 'flex-end',
        backgroundColor: 'transparent',
        color: '#232C60',
        // gap: '4px',
        padding: '8px 16px',
        boxSizing: 'border-box',
        // cursor: 'default'
    }
    const titleContainerStyle = {
        width: '95%',
        fontFamily: 'Oxanium-Bold',
        fontSize: isMatchToMobileSm? '16px' : '18px',
        textTransform: 'uppercase',
        margin: '8px',
    }
    const descriptionContainerStyle = {
        width: '95%',
        fontFamily: 'NunitoSans_7pt-Regular',
        fontSize: '12px',
        // textTransform: 'capitalize',
        margin: '8px 0px 8px 8px'
    }
    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerLayout}>
                <p style={headingStyle}>
                    <span>
                        we offer
                    </span>
                    {/* <span>
                        all things 
                    </span> */}
                </p>
                <div style={flexSubContainerStyle}>
                    <div className='we-offer-div' id='we-offer-sar' onClick={()=>{navigate('/technology/sar')}}>
                        <img src={slideList[0].image} alt={slideList[0].image} style={{width: '100%'}}/>
                        <div style={detailInfoContainerStyle}>
                            <p style={titleContainerStyle}>
                                {slideList[0].title}
                            </p>
                            <p style={descriptionContainerStyle}>
                                {slideList[0].description}
                                {/* {
                                    isMatchToTablet ? (
                                    <span style={{textDecoration: 'underline', margin: '0px 4px'}}>
                                        Know More
                                    </span>
                                    ) : (
                                        <></>
                                    )
                                } */}
                            </p>
                        </div>
                    </div>
                    <div className='we-offer-div' id='we-offer-gpr' onClick={()=>{navigate('/technology/gpr')}}>
                        <img src={slideList[1].image} alt={slideList[1].image} style={{width: '100%'}}/>
                        <div style={detailInfoContainerStyle}>
                            <p style={titleContainerStyle}>
                                {slideList[1].title}
                            </p>
                            <p style={descriptionContainerStyle}>
                                {slideList[1].description}
                                {/* {
                                    isMatchToTablet ? (
                                    <span style={{textDecoration: 'underline', margin: '0px 4px'}}>
                                        Know More
                                    </span>
                                    ) : (
                                        <></>
                                    )
                                } */}
                            </p>

                        </div>
                    </div>
                    <div className='we-offer-div' id='we-offer-hyspec' onClick={()=>{navigate('/technology/hyspec')}}>
                        <img src={slideList[2].image} alt={slideList[2].image} style={{width: '100%'}}/>
                        <div style={detailInfoContainerStyle}>
                            <p style={titleContainerStyle}>
                                {slideList[2].title}
                            </p>
                            <p style={descriptionContainerStyle}>
                                {slideList[2].description}
                                {/* {
                                    isMatchToTablet ? (
                                    <span style={{textDecoration: 'underline', margin: '0px 4px'}}>
                                        Know More
                                    </span>
                                    ) : (
                                        <></>
                                    )
                                } */}
                            </p>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
} 
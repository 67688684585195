// import node-modules ----------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import created modules -------------------------------------------------------
import DropRightMotion from '../utils/motioncomponents/DropRightMotion'

// import images ----------------------------------------------------------------
// import {
//     HomePageMainImageLg,
//     // HomePageMainImageMd,
//     // HomePageMainImageSm
// } from '../../assets/Assets'
import { 
    HomePageMainImageLg,
    HomePageMainImageMd,
    HomePageMainImageSm,
    HomePageHeaderTitleText 
} from '../../assetsv1/Assets'

// create the hero-banner section of about-us page ------------------------------
export default function Banner(){

    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm and (orientation: portrait)'))
    const isMatchToLaptop = useMediaQuery('(max-width: 960px) and (orientation: portrait)')
    // const isMatchToTablet = useMediaQuery('(max-width: 760px) and (orientation: portrait)')
    // const isMatchToMobileLg = useMediaQuery('(max-width: 580px) and (orientation: portrait)')
    // const isMatchToMobile = useMediaQuery('(max-width: 415px) and (orientation: portrait)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        position: 'relative', 
        display: 'flex', 
        width: '100vw', 
        height: 'auto',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        zindex: 1
    }
    const textContainerStyle = {
        position: 'absolute',
        display:'flex', 
        flexDirection: 'column',
        color: '#232C60', 
        width: isMatchToLaptop? '50%' :'40%', 
        height: '100%',
        alignItems: 'flex-start',
        // justifyContent: 'center',
        pl: '5vw',
        pt: '30vh',
        // pl: '10vw',
        gap: isMatchToSmallDevice? '0px' : '2px',
        // pt: isMatchToMobileLg? '5vh' : isMatchToTablet? '10vh' : isMatchToLaptop? '8vh' : '0'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <img 
                    src={isMatchToSmallDevice? HomePageMainImageSm : isMatchToLaptop ? HomePageMainImageMd :HomePageMainImageLg} 
                    // src={HomePageMainImageLg}
                    alt='Homepage' 
                    style={{width: '100vw', height:'100vh'}}
                />
                <Box sx={textContainerStyle}> 
                    <DropRightMotion delayTime={1}>
                        <img 
                            src={HomePageHeaderTitleText} 
                            alt='Pioneers In Radar Technology'
                            style={{width: isMatchToSmallDevice ? '70vw' : isMatchToLaptop ? '60vw' : '40vw'}}
                        />
                    </DropRightMotion>
                </Box>
            </Box>
        </>
    )
}
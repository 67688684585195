// import node-modules ----------------------------------------------------------
import {
    Box,
} from '@mui/material'
// import NonDestructiveSvgComponent from '../../assetsv1/images/svgicons/gprpageicons/NonDestructiveSvg'

// create custom-cards ----------------------------------------------------------
export default function CustomIconCardForCustomGPR({children, image, headText, description, iconWidth, order}){

    // style --------------------------------------------------------------------
    const cardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        gap: 'clamp(16px, 2vw, 32px)',
        flexShrink: 0,
        fontFamiy: 'prompt',
        order: order,
        width: '266px'
    }
    const iconContainerStyle = {
        display: 'flex',
        // width: 'fit-content',
        // height: 'fit-content',

        width: iconWidth

    }
    const infoContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        textTransform: 'capitalize',
        gap: '14px'
    }
    
    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={cardContainerStyle}>
                {/* <Box sx={iconContainerStyle}>
                    {children}
                </Box>                */}
                <Box sx={iconContainerStyle}>
                    <img src={image} alt={image} width={iconWidth}/>
                </Box>
                <Box sx={infoContainerStyle}>
                    <Box>
                    {
                        headText.map((boldBody, index)=>(
                            <Box 
                                key={index}
                                sx={{
                                    fontSize: '18px', 
                                    fontFamily: 'NunitoSans_7pt-Regular',
                                }}
                            >
                                {boldBody}
                            </Box>
                        ))
                    }
                    </Box>
                    <Box>
                        {
                            description.map((body, bodyIndex)=>(
                                <Box 
                                    key={bodyIndex}
                                    sx={{
                                        fontSize: '14px', 
                                        fontFamily: 'NunitoSans_7pt-ExtraLight',
                                        textTransform: 'none'
                                    }}
                                >
                                    {body}
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}
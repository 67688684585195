// import node modules ----------------------------------------------------------
import { useEffect } from "react"
import {Helmet} from 'react-helmet-async'


// import created modules -------------------------------------------------------
import Banner from "../sarcomponents/SARBanner"
import WhatIsSAR from "../sarcomponents/WhatIsSAR"
import FeaturesOfSAR from "../sarcomponents/FeaturesOfSAR"
import SARSuperiority from "../sarcomponents/SARSuperiority"
import SARRangeAndCoverage from "../sarcomponents/SARRangeCoverage"
import SARApplications from "../sarcomponents/sarapplications/SARApplications"
import SARPlatforms from "../sarcomponents/sarplatforms/SARPlatforms"
import Brochures from "../sarcomponents/brochures/Brochures"

// import images ----------------------------------------------------------------
import { FavIcon } from '../../assetsv1/Assets'

// create gpr page -------------------------------------------------------------
export default function SAR(){

    // scroll vertically to zero on load ----------------------------------------
    useEffect(() => {
        window.scrollTo(0,0)
      }, []);

    return(
        <>
            <Helmet>
                <title>SAR | Technology | Sisir Radar</title>
                <meta name='description'content='Get a better understanding of SAR and its various military and civilian applications.' data-rh='true' />
                <link rel='cannonical' href='/technology/sar'/>
                <link rel="icon" href={FavIcon} />
            </Helmet>
            <Banner/>
            <WhatIsSAR/>
            <FeaturesOfSAR/>
            <SARSuperiority/>
            <SARRangeAndCoverage/>
            <SARPlatforms/>
            <SARApplications/>
            <Brochures/>
        </>
    )
}

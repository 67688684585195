// import created modules -------------------------------------------------------


// import images ----------------------------------------------------------------
import {
    GPRDrone,
    GPRRover,
    HandheldGPR
} from '../../../assetsv1/Assets'
import './GPRPlatforms.css'

// create main layout
export default function GPRPlatforms(){
    // variables-----------------------------------------------------------------


    // style --------------------------------------------------------------------

    // main-layout --------------------------------------------------------------
    return(
        <>
            <div className='gpr-platforms-main-div'>
                <div className='gpr-upper-div'>
                    <p className='gpr-platform-heading'>Platforms</p>
                </div>
                <div className='gpr-lower-div'>
                    <div className='gpr-circle-container'>
                        <div className='gpr-circle' id='gpr-drone'>
                            <img src={GPRDrone} alt={GPRDrone} className='platform-image' id='gpr-drone-image'/>
                            <p className='gpr-platform-name' id='gpr-platform-drone'>drone</p>
                        </div>
                        <div className='gpr-circle' id='gpr-rover'>
                            <img src={GPRRover} alt={GPRRover} className='platform-image' id='gpr-rover-image'/>
                            <p className='gpr-platform-name'id='gpr-platform-rover'>motorized rover</p>
                        </div>
                        <div className='gpr-circle' id='gpr-scanner'>
                            <img src={HandheldGPR} alt={HandheldGPR} className='platform-image' id='gpr-scanner-image'/>
                            <p className='gpr-platform-name' id='gpr-platform-scanner'>handheld scanner</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

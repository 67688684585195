// import created modules -------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import images ----------------------------------------------------------------
import { TributeBgImage, SisirMitraLg, SisirMitraSm } from '../../assetsv1/Assets'

// create definition-of-sar component -------------------------------------------
export default function Tribute2Legend(){

    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        background: `url(${TributeBgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100vw',
        height: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        wrap: 'wrap',
    }
    const flexSubContainerStyle = {
        display: 'flex', 
        flexDirection: 'column',
        width: '90%', 
        height: 'auto', 
        justifyContent: 'space-evenly',
        alignItems: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '52px' : '48px',
    }
    const headerTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        color: '#232C60',
        maxWidth: '80%',
        alignItems: 'center',
        textTransform: 'uppercase',
        textAlign: 'center',
        wordSpacing: '6px'
    }
    const imageContainerStyle = {
        maxWidth: '650px', 
        minWidth: '250px', 
        display: 'flex', 
        flexShrink: 1.4,
        borderRadius: '10px',

    }
    const descriptionTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        color: '#232C60',
        maxWidth: '875px',
        minWidth: '300px',
        alignItems: 'flex-start',
        fontSize: 'clamp(16px, 2vw, 18px)',
        fontFamily: 'NunitoSans_7pt-Regular',
        // fontWeight: '600'
    }
    
    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={headerTextContainerStyle}>
                        <p 
                            style={{
                                fontFamily:'Oxanium-Bold', 
                                textTransform: 'uppercase', 
                                fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px'
                            }}
                        >
                            Tribute to a legend
                        </p>
                    </Box>
                    <Box sx={imageContainerStyle}>
                        <img 
                            src={isMatchToSmallDevice? SisirMitraSm : SisirMitraLg} 
                            alt='Sisir Mitra' 
                            style={{
                                width: isMatchToSmallDevice? '90%' : isMatchToTablet? '80%' : '90%', 
                                margin: 'auto'
                            }}
                        />
                    </Box>
                    <Box sx={descriptionTextContainerStyle}>
                        Even before Radar was discovered, Professor Sisir K. Mitra utilised the direction of arrival of RF signals, reflected from atmospheric layers, to measure their heights. He carried out those pathbreaking experiments in Kolkata. In a sense, the methodology used was a prehistoric method of detection of heights by the forward reflection of CW signals, somewhat akin to bistatic radars as we know them. We are proud to name our start-up Sisir Radar in honour of the memory of the forgotten Indian physicist.
                    </Box>
                </Box>
            </Box>
        </>
    )
}
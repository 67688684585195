// import node modules ----------------------------------------------------------
import { useEffect } from "react"
import {Helmet} from 'react-helmet-async'


// import created modules -------------------------------------------------------
import Banner from "../gprcomponents/GPRBannner"
import WhatIsGPR from "../gprcomponents/WhatIsGPR"
import FeaturesOfGPR from "../gprcomponents/FeaturesOfGPR"
import AdvantagesOfCustomGPR from "../gprcomponents/AdvantagesOfCustomGPR"
import USP from "../gprcomponents/USP"
import GPRPlatforms from "../gprcomponents/gprplatforms/GPRPlatforms"
import GPRApplications from "../gprcomponents/gprapplications/GPRApplications"
import Brochures from "../gprcomponents/brochures/Brochures"

// import images ----------------------------------------------------------------
import { FavIcon } from '../../assetsv1/Assets'

// create gpr page -------------------------------------------------------------
export default function GPR(){

    // scroll vertically to zero on load ----------------------------------------
    useEffect(() => {
        window.scrollTo(0,0)
      }, []);

    return(
        <>
            <Helmet>
                <title>GPR | Technology | Sisir Radar</title>
                <meta name='description'content='Get a better understanding of GPR and its various military and civilian applications.' data-rh='true' />
                <link rel='cannonical' href='/technology/gpr'/>
                <link rel="icon" href={FavIcon} />
            </Helmet>
            <Banner/>
            <WhatIsGPR/>
            <FeaturesOfGPR/>
            <AdvantagesOfCustomGPR/>
            <USP/>
            <GPRPlatforms/>
            <GPRApplications/>
            <Brochures/>
            
        </>
    )
}

// import node-modules ----------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import images ----------------------------------------------------------------
import { 
    SARHeaderImageLg,
    SARHeaderImageMd,
    SARHeaderImageSm,
    SARHeaderTitleText,
    SARHeaderSubTitleText
} from '../../assetsv1/Assets'
import DropRightMotion from '../utils/motioncomponents/DropRightMotion'

// create the hero-banner section of about-us page ------------------------------
export default function Banner(){
    
    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToLaptop = useMediaQuery('(max-width: 960px) and (orientation: portrait)')
    // const isMatchToTablet = useMediaQuery('(max-width: 760px) and (orientation: portrait)')
    // const isMatchToMobile = useMediaQuery('(max-width: 415px) and (orientation: portrait)')
    // const isMatchToMobileLg = useMediaQuery('(max-width: 580px) and (orientation: portrait)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        position: 'relative', 
        display: 'flex', 
        width: '100vw', 
        height: 'auto',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    }
    const textContainerStyle = {
        position: 'absolute',
        display:'flex', 
        flexDirection: 'column',
        color: '#fff', 
        width: isMatchToLaptop? '50%' :'40%', 
        height: '100%',
        alignItems: 'flex-start',
        // justifyContent: 'center',
        // fontFamily: 'prompt',
        // pl: '10vw',
        pl: '5vw',
        pt: '40vh',
        gap: '16px'
        // background: 'linear-gradient(to right, #232C60, transparent)',
        // transition: 'background 1s ease'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                {/* <img src={isMatchToSmallDevice? AboutUsHeaderImageSm : isMatchToLaptop? AboutUsHeaderImageMd : AboutUsHeaderImageLg} alt='About Us' style={{width: '100vw', height:'100vh'}}/> */}
                <img 
                    src={isMatchToSmallDevice? SARHeaderImageSm : isMatchToLaptop? SARHeaderImageMd : SARHeaderImageLg} 
                    alt='SAR' 
                    style={{
                        height: '100vh',
                        width: '100vw'
                    }}
                />
                <Box sx={textContainerStyle}> 
                    <DropRightMotion delayTime={0.5}>
                        <img 
                            src={SARHeaderTitleText}
                            alt='Synthetic Aparture Radar'
                            style={{width: isMatchToSmallDevice ? '70vw' : isMatchToLaptop ? '60vw' : '40vw'}}
                        />
                    </DropRightMotion>
                    <DropRightMotion delayTime={0.5}>
                        <img 
                            src={SARHeaderSubTitleText}
                            alt="World's Highest resolution L Band SAR"
                            style={{width: isMatchToSmallDevice ? '65vw' : isMatchToLaptop ? '55vw' : '35vw'}}
                        />
                    </DropRightMotion>
                </Box>
            </Box>
        </>
    )
}
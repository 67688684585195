// import node-modules ----------------------------------------------------------
import { 
    Box, 
    useMediaQuery,
} from '@mui/material'

// import created modules -------------------------------------------------------
import { TextMotionOnWords, TextMotionOnCharacters } from '../utils/motioncomponents/TextMotion'
// import DropLeftMotion from '../utils/motioncomponents/DropLeftMotion'
// import DropRightMotion from '../utils/motioncomponents/DropRightMotion'
// import FadeInMotion from '../utils/motioncomponents/FadeInMotion'

// import images ----------------------------------------------------------------
// import {
//     OutdoorSisirWork,
//     IndoorSisirWork2
// } from '../../assets/Assets'
import { IndoorSisirWork2, OutdoorSisirWork, CompanyGoalBgImageLg } from '../../assetsv1/Assets'


// create the company breif and goal layout -------------------------------------
export default function CompanyGoal(){

    // const theme & media-query ------------------------------------------------
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLgTablet = useMediaQuery('(max-width: 1350px)')
    const isMatchToLgTablet2 = useMediaQuery('(max-width: 1150px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        width: '100vw',
        color: '#fff',
        fontFamily: 'prompt',
        justifyContent: 'center',
        // backgroundColor: '#232C60'
        background: `url(${CompanyGoalBgImageLg})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        alignitems: 'center',
        justifyContent: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '64px' : '78px',
    }
    const child1Style = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: isMatchToTablet? '52px' : isMatchToLgTablet? '64px' : '48px',
    }
    const child2Style = {
        display: 'flex',
        flexFlow: 'row wrap-reverse',
        height: 'fit-content',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: isMatchToTablet? '52px' : isMatchToLgTablet? '64px' : '48px',
    }
    const grndChildStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: isMatchToTablet? '70vw' : isMatchToLgTablet2? '50%' : '40%',
        justifyContent: 'space-between',
        fontSize: 'clamp(14px, 2vw, 16px)'
    }
    
    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                <TextMotionOnCharacters 
                                    text="ABOUT THE COMPANY"
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                    }}
                                />
                                <TextMotionOnWords
                                    text="Sisir Radar is a DeepTech startup that specialises in cutting-edge radar technology. Led by the former Director of Space Applications Centre (SAC), ISRO and Physical Research Laboratory (PRL) – Dr Tapan Misra, Sisir Radar has achieved a significant breakthrough with the world's highest resolution L band Synthetic Aperture Radar (SAR)."
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'NunitoSans_7pt-ExtraLight', 
                                        fontSize: '16px',
                                        display: 'flex',
                                        flexFlow: 'wrap'
                                    }}
                                    delayTime={0}
                                />
                                <TextMotionOnWords
                                    text="Sisir Radar has also successfully developed high-resolution Ground Penetrating Radars (GPR) that have been tested in some of the most challenging mines in the country. The company's innovative solutions promise to reshape remote sensing across various industries, setting new standards for data accuracy and accessibility."
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'NunitoSans_7pt-ExtraLight', 
                                        fontSize: '16px',
                                        display: 'flex',
                                        flexFlow: 'wrap'
                                    }}
                                    delayTime={0.5}
                                />
                            </Box>
                        </Box>
                        <Box sx={grndChildStyle}>
                            {/* <FadeInMotion delayTime={0}>
                                <img src={OutdoorSisirWork} alt='Sisir Radar' style={{width: isMatchToLgTablet? '90%' : '80%', alignSelf: 'center' }}/>
                            </FadeInMotion> */}
                        <img src={IndoorSisirWork2} alt='Sisir Radar' style={{width: isMatchToLgTablet? '90%' : '80%', alignSelf: 'center' }}/>
                        </Box>
                    </Box>
                    <Box sx={child2Style}>
                        <Box sx={grndChildStyle}>
                            {/* <FadeInMotion delayTime={0}>
                                <img src={IndoorSisirWork2} alt='Our Goal' style={{width: isMatchToLgTablet? '90%' :  '80%', alignSelf: 'center' }}/>
                            </FadeInMotion> */}
                            <img src={OutdoorSisirWork} alt='Our Goal' style={{width: isMatchToLgTablet? '90%' :  '80%', alignSelf: 'center' }}/>
                        </Box>
                        <Box sx={grndChildStyle}>
                            <Box sx={{width: isMatchToLgTablet? '100%' :  '80%', gap: '20px', display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                                <TextMotionOnCharacters 
                                    text="OUR GOAL"
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                    }}
                                    delayTime={0}
                                />
                                <TextMotionOnWords
                                    text="At Sisir Radar, our unwavering commitment is to propel India to the pinnacle of radar technology on the global stage. We are dedicated to achieving this vision through an array of pioneering DeepTech innovations, spanning from Space-borne to Underground Radars. Our goal is to not only revolutionise the radar industry but also empower our nation's technological prowess, contributing significantly to its growth and influence on the world stage."
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'NunitoSans_7pt-ExtraLight', 
                                        fontSize: '16px',
                                        display: 'flex',
                                        flexFlow: 'wrap'
                                    }}
                                    delayTime={0}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
// import node modules ----------------------------------------------------------
import React, { useState } from "react"
import { useMediaQuery } from "@mui/material"
// import { motion } from "framer-motion"

// import images ----------------------------------------------------------------
import {
    Rectangle0,
    Rectangle1,
    Rectangle2,
    Rectangle3,
    Rectangle4,
    Rectangle5,
    UnselectedIconInTimeline,
    SelectedIconInTimeline,
    // LineForTimeline,
    SelectedIconInTimelineWhiteOutlined,
    UnselectedIconInTimelineWhiteFilled,
    ArrowBackwardBlueFilled,
    // ArrowBackwardBlueOutlined,
    ArrowForwardBlueFilled,
    // ArrowForwardBlueOutlined,
    ArrowBackwardWhiteFilled,
    ArrowForwardWhiteFilled
} from '../../../assetsv1/Assets'
import './TimeLineNew.css'
// import { color } from "framer-motion"

// create list ------------------------------------------------------------------
const TimeLineDataList = [
    {
        image: Rectangle0,
        detail: {
            timeline: "JAN 2023",
            work: "Launch of the ruggedised Ultra Wide Band Ground Penetrating Radar (GPR)"
        }
    },
    {
        image: Rectangle1,
        detail: {
            timeline: "MARCH 2023",
            work: "Evaluation and testing of the Hyperspectral Single Pixel Classifier"
        }
    },
    {
        image: Rectangle2,
        detail: {
            timeline: "JUNE 2023",
            work: "Trial runs of the drone-borne Synthetic Aperture Radar (SAR)"
        }
    },
    {
        image: Rectangle3,
        detail: {
            timeline: "SEPT 2023",
            work: "Operationalisation of the Near Field (NF) Facility"
        }
    },
    {
        image: Rectangle4,
        detail: {
            timeline: "OCT 2023",
            work: "Finalisation of the SAR Processor and Navigation Software"
        }
    },
    {
        image: Rectangle5,
        detail: {
            timeline: "OCT 2023",
            work: "Won 2 iDEX Competitions for developing Spaceborne L and P Band SAR Payloads"
        }
    }
]

// create carousel --------------------------------------------------------------
export default function OurTimeLine(){
    // variables ----------------------------------------------------------------
    const [currentIndex, setCurrentIndex] = useState(0)
    // const [wheelCount, setWheelCount] = useState(0)
    const [startTouchX, setStartTouchX] = useState(0)
    const [deltaX, setDeltaX] = useState(0)
    const [isSliding, setIsSliding] = useState(false)

    // mediaqueries
    // const isMatchToTabletSm = useMediaQuery('(max-width: 640px)')
    const isMatchToMobile = useMediaQuery('(max-width: 600px)')

    // style --------------------------------------------------------------------

    // functions ----------------------------------------------------------------
    // useEffect(()=>{
    //     setInterval(() => {
    //         setCurrentIndex(currentIndex === (TimeLineDataList.length - 1) ? 0 : (currentIndex+1))
    //         console.log('running index', currentIndex)
    //     }, 2000);
    // },[])

    // function handleWheel(e){
    //     if(e.deltaY > 0){
    //         // setWheelCount(wheelCount === 3 ? 0 : wheelCount+1)
    //         // if(wheelCount === 5){
    //         //     setCurrentIndex(currentIndex < 5 ? currentIndex+1 : 5)
    //         // }
    //         setCurrentIndex(currentIndex < 5 ? currentIndex+1 : 5)
            
    //     }
    //     console.log(e.deltaY)
    //     if(currentIndex < 5){
    //         document.body.style.overflowY = 'hidden'
    //     }else{
    //         document.body.style.overflowY = 'scroll'
    //     }
    // }
    function handleTouchStart(e){
        setIsSliding(true)
        setStartTouchX(e.touches[0].pageX)
    }
    function handleTouchCancel(e){
        setIsSliding(false)
        setStartTouchX(null)
    }
    function handleTouchEnd(e){
        if (deltaX < 0){
            setCurrentIndex(currentIndex === 5 ? 0 : currentIndex+1)
        }else if (deltaX > 0){
            setCurrentIndex(currentIndex === 0 ? 5 : currentIndex-1)
        }else{
            setCurrentIndex(currentIndex)
        } 
        setIsSliding(false)
        setStartTouchX(null)
    }
    function handleTouchMove(e){
        if(startTouchX !== null && isSliding === true){
            const currentX = e.touches[0].pageX
            const deltaX = currentX - startTouchX
            setDeltaX(deltaX)
            // console.log(deltaX)
            // if (deltaX < 0){
            //     setCurrentIndex(currentIndex === 5 ? 0 : currentIndex+1)
            // }else if (deltaX > 0){
            //     setCurrentIndex(currentIndex === 0 ? 5 : currentIndex-1)
            // }else{
            //     setCurrentIndex(currentIndex)
            // } 
            // console.log(currentIndex)
        }
        // console.log(e.touches)
    }
    function handlePrevSlide(e){
        setCurrentIndex(currentIndex === 0 ? (TimeLineDataList.length - 1) : (currentIndex-1))
    }
    function handleNextSlide(){
        setCurrentIndex(currentIndex === (TimeLineDataList.length - 1) ? 0 : (currentIndex+1))
    }
    // main layout --------------------------------------------------------------
    return(
        <>
        {
            isMatchToMobile ? (
                <div className="timeline-wrapper-sm">
                   <div className="timeline-heading-sm">timeline</div>
                   <div 
                        onTouchStart={(e)=>{handleTouchStart(e)}}
                        onTouchMove={(e)=>{handleTouchMove(e)}}
                        onTouchEnd={(e)=>{handleTouchEnd(e)}}
                        onTouchCancel={(e)=>{handleTouchCancel(e)}}
                        className="timeline-carousel-div-sm"
                    >
                        {
                            TimeLineDataList.map((item, index)=>(
                                <div key={index} className="timeline-image-div-sm" style={{translate: `${currentIndex * (-100)}%`}}>
                                    <img src={item.image} alt={item.image} className="timeline-image-sm"/>
                                    <div className="timeline-detail-sm">
                                        <h1 className="timeline-detail-heading-sm">
                                            {item.detail.timeline}
                                        </h1>
                                        <p className="timeline-detail-work-sm">
                                            {item.detail.work}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                   </div>
                   <div className="timeline-carousel-navigator-sm">
                        <div className="timeline-prev-btn" onClick={(e)=>{handlePrevSlide(e)}}>
                            <img src={ArrowBackwardBlueFilled} alt="back-btn" className="timeline-back-arrow-img"/>
                        </div>
                        <div className="timeline-indicator-div-sm">
                            {
                                TimeLineDataList.map((item, index)=>(
                                    <div key={index} className="timeline-indicator-div-child-sm">
                                        <div className="timeline-circle-indicator-div-sm" onClick={()=>{setCurrentIndex(index)}}>
                                            <img src={currentIndex === index ? SelectedIconInTimeline : UnselectedIconInTimeline} alt={UnselectedIconInTimeline} className="timeline-circle-indicator"/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="timeline-next-btn" onClick={()=>{handleNextSlide()}}>
                            <img src={ArrowForwardBlueFilled} alt="for-btn" className="timeline-for-arrow-img"/>
                        </div>
                   </div>
                </div>
            ) : (
                <div className="timeline-wrapper-lg">
                    <h1>Timeline</h1>
                    <div className="timeline-carousel-container-lg">
                        <div className="timeline-indicator-container-lg">
                            <div className="timeline-indicator-child-container-lg">
                            {
                                TimeLineDataList.map((item, index)=>(
                                    <div key={index} className="timeline-indicator-lg" onClick={()=>{setCurrentIndex(index)}}>
                                        <img src={currentIndex === index ? SelectedIconInTimelineWhiteOutlined : UnselectedIconInTimelineWhiteFilled} alt={UnselectedIconInTimelineWhiteFilled}/>
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                        <div className="timeline-image-container-lg">
                            <div className="timeline-image-child-container-lg">
                                <img src={TimeLineDataList[currentIndex].image} alt="image" />
                            </div>
                        </div>
                        <div className="timeline-detail-info-and-btn-container-lg">
                            <div className="timeline-detail-info-conatiner-lg">
                                {/* <h2>Timeline</h2> */}
                                <h1>{TimeLineDataList[currentIndex].detail.timeline}</h1>
                                <p>{TimeLineDataList[currentIndex].detail.work}</p>
                            </div>
                            <div className="timeline-navigator-btn-container-lg">
                                <div className="timeline-prev-btn-lg" onClick={(e)=>{handlePrevSlide(e)}}>
                                    <img src={ArrowBackwardWhiteFilled} alt="back-btn"/>
                                </div>
                                <div className="timeline-next-btn-lg" onClick={()=>{handleNextSlide()}}>
                                    <img src={ArrowForwardWhiteFilled} alt="for-btn"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        </>
    )
}
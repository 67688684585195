// import node modules ----------------------------------------------------------
import React, { useState } from "react"
import { useMediaQuery } from "@mui/material"

// import images ----------------------------------------------------------------
import {
    MineralMining,
    LandMineDetection,
    Archaeology,
    UtilitiesLocation,
    InfrastructureAssessment,
    ConstructionMonitoring,
    PipelineMaintenance,
    SewageMonitoring,
    UndergroundTunnelsDetection,
    MineralMiningSquare,
    LandMineDetectionSquare,
    ArchaeologySquare,
    UtilitiesLocationSquare,
    InfrastructureAssessmentSquare,
    ConstructionMonitoringSquare,
    PipelineMaintenanceSquare,
    SewageMonitoringSquare,
    UndergroundTunnelsDetectionSquare,
    ArrowBackwardWhiteFilled,
    ArrowBackwardWhiteOutlined,
    ArrowForwardWhiteFilled,
    ArrowForwardWhiteOutlined,
} from '../../../assetsv1/Assets'
import './GPRApplications.css'

// create list of applications --------------------------------------------------
const GPRApplicationList = [
    {
        image: MineralMining,
        squareImage: MineralMiningSquare,
        headText: 'MINERAL MINING',
        Description: [
            {
                mainText: 'Get accurate orebody delineation. Find massive ore pockets as well as narrow ore veins in host rock, locate aquifers, and assess caving/flooding risks.',
                subText: [],
            },
        ]
    },
    {
        image: LandMineDetection,
        squareImage: LandMineDetectionSquare,
        headText: 'LAND MINE DETECTION',
        Description: [
            {
                mainText: 'Track down land mines with pinpoint accuracy without compromising the safety of any personnel in any terrain.',
                subText: [],  
            },
        ]
    },
    {
        image: Archaeology,
        squareImage: ArchaeologySquare,
        headText: 'ARCHAEOLOGY',
        Description: [
            {
                mainText: 'Discover buried structures without disturbing surface level environment. Plan precise digs based on the GPR survey data.',
                subText: [],
            },
        ]
    },
    {
        image: UtilitiesLocation,
        squareImage: UtilitiesLocationSquare,
        headText: 'UTILITIES LOCATION',
        Description: [
            {
                // mainText: 'Locate water pipes, gas lines, electrical pipes buried underground. Measure accurate estimation of depth and orientation. This helps in optimally planning urban infrastructure upgrade programs.',
                mainText: 'Locate water pipes, gas lines, electrical pipes buried underground. Accurately estimate depth and orientation. This helps in optimally planning urban infrastructure upgrade programs.',
                subText: [],
            },
        ]
    },
    {
        image: InfrastructureAssessment,
        squareImage: InfrastructureAssessmentSquare,
        headText: 'INFRASTRUCTURE ASSESSMENT',
        Description: [
            {
                mainText: 'Find subsurface defects and structural vulnerabilities. Assess the quality of roads, tunnels, bridges and pavements.',
                subText: [],
            },
        ]
    },
    {
        image: ConstructionMonitoring,
        squareImage: ConstructionMonitoringSquare,
        headText: 'CONSTRUCTION MONITORING',
        Description: [
            {
                mainText: 'Evaluate the quality of pillars, beams, walls and slabs during and after construction. Find cracks, cavities, rebar vulnerabilities and assess structural integrity.',
                subText: [],  
            },
        ]
    },
    {
        image: PipelineMaintenance,
        squareImage: PipelineMaintenanceSquare,
        headText: 'PIPELINE MAINTENANCE',
        Description: [
            {
                mainText: 'Precisely locate cracks and defects in underground pipelines, saving time and money.',
                subText: [],
            },
        ]
    },
    {
        image: SewageMonitoring,
        squareImage: SewageMonitoringSquare,
        headText: 'SEWAGE MONITORING',
        Description: [
            {
                mainText: 'Detect blockades and flow of underground sewer system.',
                subText: [],
            },
        ]
    },
    {
        image: UndergroundTunnelsDetection,
        squareImage: UndergroundTunnelsDetectionSquare,
        headText: 'UNDERGROUND TUNNEL DETECTION',
        Description: [
            {
                mainText: 'Monitor underground activities including the presence of underground tunnels in sensitive regions.',
                subText: [],
            },
        ]
    },
]

// create carousel --------------------------------------------------------------
export default function GPRApplications(){
    // variables ----------------------------------------------------------------
    const [activeIndex, setActiveIndex] = useState(0)
    const isMatchToTablet = useMediaQuery('(max-width: 840px)')
    const isMatchToTabletLg = useMediaQuery('(max-width: 960px)')
    const [isPrevBtnHovered, setIsPrevBtnHovered] = useState(false)
    const [isNextBtnHovered, setIsNextBtnHovered] = useState(false)
    // const [isDragging, setIsDragging] = useState(false)

    const [startTouchX, setStartTouchX] = useState(0)
    const [deltaX, setDeltaX] = useState(0)
    const [isSliding, setIsSliding] = useState(false)
    // const gprCarousel1 = document.querySelector('.lg-child-1')
    // const gprCarousel = document.querySelector('.lg-child')
    // style --------------------------------------------------------------------

    // functions ----------------------------------------------------------------
    // handle button clicks
    function handlePrevSlide(){
        setActiveIndex(activeIndex === 0 ? ((isMatchToTablet? GPRApplicationList.length : 3)-1) : (activeIndex-1))
    }
    function handleNextSlide(){
        setActiveIndex(activeIndex === ((isMatchToTablet? GPRApplicationList.length : 3) - 1) ? 0 : (activeIndex+1))
    }
    // function handleDragging(e){
    //     if(isDragging){
    //         gprCarousel1.scrollLeft = e.pageX
    //         gprCarousel.scrollLeft = e.pageX
    //     }
    // }

    function handleTouchStart(e){
        setIsSliding(true)
        setStartTouchX(e.touches[0].pageX)
    }
    function handleTouchCancel(e){
        setIsSliding(false)
        setStartTouchX(null)
    }
    function handleTouchEnd(e){
        if (deltaX < 0){
            setActiveIndex(activeIndex === (GPRApplicationList.length - 1) ? 0 : activeIndex+1)
        }else if (deltaX > 0){
            setActiveIndex(activeIndex === 0 ? (GPRApplicationList.length - 1) : activeIndex-1)
        }else{
            setActiveIndex(activeIndex)
        } 
        setIsSliding(false)
        setStartTouchX(null)
    }
    function handleTouchMove(e){
        if(startTouchX !== null && isSliding === true){
            const currentX = e.touches[0].pageX
            const deltaX = currentX - startTouchX
            setDeltaX(deltaX)
        }
    }
    // main layout --------------------------------------------------------------
    return(
        <>
            <div className="gpr-carousel-outer-div">
                <div className="gpr-carousel-heading-container">
                    <p className="gpr-carousel-heading">
                        gpr applications
                    </p>
                </div>
                <div className="gpr-carousel-inner-div">
                    <div 
                        className="gpr-carousel-prev-btn-container" 
                        onTouchStart={()=>{setIsPrevBtnHovered(true)}}
                        onTouchEnd={()=>{setIsPrevBtnHovered(false)}}
                        onMouseEnter={()=>{setIsPrevBtnHovered(true)}}
                        onMouseLeave={()=>{setIsPrevBtnHovered(false)}}
                        onClick={()=>{handlePrevSlide()}}
                    >
                        <img src={isPrevBtnHovered?ArrowBackwardWhiteOutlined : ArrowBackwardWhiteFilled} alt="prev" className="prev-btn"/>
                    </div>
                    {
                        isMatchToTablet ? (
                            <div 
                                className="gpr-carousel-div-1"
                                onTouchStart={(e)=>{handleTouchStart(e)}}
                                onTouchMove={(e)=>{handleTouchMove(e)}}
                                onTouchEnd={(e)=>{handleTouchEnd(e)}}
                                onTouchCancel={(e)=>{handleTouchCancel(e)}}
                            >
                                <div 
                                    // onMouseMove={()=>{handleDragging()}}
                                    // onMouseDown={()=>{setIsDragging(true); gprCarousel1.classList.add('dragging')}}
                                    // onMouseUp={()=>{setIsDragging(false)}}
                                    className="lg-child-1" 
                                    style={{translate: `${activeIndex * (-100)}%`}}
                                >
                                    {
                                        GPRApplicationList.map((item, index)=>(
                                            <div key={index} className="gpr-application-1" >
                                                <img src={item.squareImage} alt={item.squareImage} className="gpr-application-img-1"/>
                                                {/* <p className="gpr-application-title-1">{item.headText}</p> */}
                                                <div className="text-overlay-1">
                                                    <p className="text-overlay-heading-1">
                                                        {item.headText}
                                                    </p>
                                                    <p className="text-overlay-description-1">
                                                        {item.Description[0].mainText}
                                                    </p>
                                                    {/* <ul className="text-overlay-description-1">
                                                        {
                                                            item.Description.map((text, idx)=>(
                                                                <li key={idx}>
                                                                    {text.mainText}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="gpr-carousel-div">
                                <div className="lg-child" id="child-1" style={{translate: `${activeIndex * (-100)}%`}}>
                                    {
                                        GPRApplicationList.slice(0, 3).map((item, index)=>(
                                            <div key={index} className="gpr-application" >
                                                <img src={isMatchToTabletLg ? item.squareImage : item.image} alt={item.image} className="gpr-application-img"/>
                                                <p className="gpr-application-title">{item.headText}</p>
                                                <div className="text-overlay">
                                                    <p className="text-overlay-heading">
                                                        {item.headText}
                                                    </p>
                                                    <p className="text-overlay-description">
                                                        {item.Description[0].mainText}
                                                    </p>
                                                    {/* <ul className="text-overlay-description">
                                                        {
                                                            item.Description.map((text, idx)=>(
                                                                <li key={idx}>
                                                                    {text.mainText}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="lg-child" id="child-2" style={{translate: `${activeIndex * (-100)}%`}}>
                                    {
                                        GPRApplicationList.slice(3, 6).map((item, index)=>(
                                            <div key={index} className="gpr-application" >
                                                <img src={isMatchToTabletLg ? item.squareImage : item.image} alt={item.image} className="gpr-application-img"/>
                                                <p className="gpr-application-title">{item.headText}</p>
                                                <div className="text-overlay">
                                                    <p className="text-overlay-heading">
                                                        {item.headText}
                                                    </p>
                                                    <p className="text-overlay-description">
                                                        {item.Description[0].mainText}
                                                    </p>
                                                    {/* <ul className="text-overlay-description">
                                                        {
                                                            item.Description.map((text, idx)=>(
                                                                <li key={idx}>
                                                                    {text.mainText}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="lg-child" id="child-3" style={{translate: `${activeIndex * (-100)}%`}}>
                                    {
                                        GPRApplicationList.slice(6, 9).map((item, index)=>(
                                            <div key={index} className="gpr-application" >
                                                <img src={isMatchToTabletLg ? item.squareImage : item.image} alt={item.image} className="gpr-application-img"/>
                                                <p className="gpr-application-title">{item.headText}</p>
                                                <div className="text-overlay">
                                                    <p className="text-overlay-heading">
                                                        {item.headText}
                                                    </p>
                                                    <p className="text-overlay-description">
                                                        {item.Description[0].mainText}
                                                    </p>
                                                    {/* <ul className="text-overlay-description">
                                                        {
                                                            item.Description.map((text, idx)=>(
                                                                <li key={idx}>
                                                                    {text.mainText}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    }
                    <div
                        className="gpr-carousel-next-btn-container" 
                        onTouchStart={()=>{setIsNextBtnHovered(true)}}
                        onTouchEnd={()=>{setIsNextBtnHovered(false)}}
                        onMouseEnter={()=>{setIsNextBtnHovered(true)}}
                        onMouseLeave={()=>{setIsNextBtnHovered(false)}} 
                        onClick={()=>{handleNextSlide()}}
                    >
                        <img src={isNextBtnHovered ? ArrowForwardWhiteOutlined : ArrowForwardWhiteFilled} alt="next" className="next-btn"/>
                    </div>
                </div>
            </div>
        </>
    )
}

// import node modules ----------------------------------------------------------
import { useEffect } from "react"
import {Helmet} from 'react-helmet-async'


// import created modules -------------------------------------------------------
import Banner from "../homecomponents/HomeBanner"
import IdexAwardReceivingSection from "../homecomponents/IdexAwardReceivingSection"
import Partners from "../homecomponents/Partners"
import AsSeenOn from "../homecomponents/AsSeeOnSection"
import WeOfferAllThings from "../homecomponents/WeOfferSection"
// import InNews from "../homecomponents/NewsSection"
// import SARApplication from "../homecomponents/SARApplications"
// import SpaceGradeTech from "../homecomponents/SpaceGradeTech"

// import images ----------------------------------------------------------------
import { FavIcon } from '../../assetsv1/Assets'

// create home page -------------------------------------------------------------
export default function Home({page, setPage}){

    // scroll vertically to zero on load ----------------------------------------
    useEffect(() => {
        window.scrollTo(0,0)
      }, []);

    return(
        <>
            <Helmet>
                <title>Sisir Radar</title>
                <meta name='description'content="Sisir Radar is building India's first drone-borne Synthetic Aperture Radar! Now map the terrain in any weather; even in the rain, fog, sand storms, clouds and at night." />
                <link rel='cannonical' href='/'/>
                <link rel="icon" href={FavIcon} />
            </Helmet>
                <Banner/>
                <IdexAwardReceivingSection/>
                <Partners/>
                <WeOfferAllThings/>
                <AsSeenOn/>
                {/* <SpaceGradeTech page={page} setPage={setPage} />
                <SARApplication 
                    mainHeadText={'SAR APPLICATIONS'} 
                    backgroundColor={'#F7F5F5'} 
                    textColor={'#232C60'}
                    mainContentBackgroundColor={'#232C60'} 
                    mainContentTextColor={'#fff'}
                />
                <InNews /> */}
        </>
    )
}

// import node-modules ----------------------------------------------------------
import {
    Box, 
    useMediaQuery, 
    useTheme
} from '@mui/material'
import { useState } from 'react'

// import created modules -------------------------------------------------------
// import ScaleMotion from '../utils/motioncomponents/ScaleMotion'
// import DropInMotion from '../utils/motioncomponents/DropInMotion'

// import icon images -----------------------------------------------------------
// import {
//     ForwardArrowNormal,
//     ForwardArrowBold
// } from '../../assets/Assets'

// import created modules -------------------------------------------------------
import CustomModal from './CustomModal'


// create custom-cards for founders and advisors as well ------------------------
export default function CustomCard({name, personImage, designation, mediaIcons, description}){

    // state variable -----------------------------------------------------------
    const [mediaIconHovered, setMediaIconHovered] = useState({})
    // const [forwardArrowHovered, setForwardArrowHovered] = useState(false)
    const [closeModal, setCloseModal] = useState(true)
    
    // const theme and breakpoints ----------------------------------------------
    const theme = useTheme()
    const isMobile= useMediaQuery(theme.breakpoints.down('md'))
    
    // style --------------------------------------------------------------------
    const cardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'start',
        padding: '4px',
        gap: '0.7rem',
        flexShrink: 0,
        width: '260px',
        height: '430px',
        backgroundColor: '#fff',
        color: '#232C60',
        borderRadius: '8px'
    }
    const imageContainerStyle = {
        display: 'flex',
        width: 'fit-content',
        height: 'fit-content',
        alignSelf: 'center'
    }
    const infoContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        width: '98%',
        gap: '8px',
        alignSelf: 'center'
    }
    const briefInfoContainerStyle = {
        display: 'flex',
        gap: '8px',
        width: '100%',
    }
    const child1Style = {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        width: '100%',
        gap: '4px'
    }
    // const child2Style = {
    //     display: 'flex',
    //     justyfySelf: 'flex-end',
    //     alignSelf: 'flex-start'
    // }
    const mediaContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        float: 'left',
        gap: '0.5rem',
        width: '100%',
        mt: '0.3rem'
    }
    const knowMoreBtnStyle = {
        padding: '4px 8px 4px 8px',
        border: 'solid #232C60',
        backgroundColor: 'transparent',
        color: '#232C60',
        borderRadius: '4px',
        mt: 'auto',
        ml: '4px',
        mb: '10px',
        fontFamily: 'NunitoSans_7pt-Bold',
        fontSize: '12px',
        alignSelf: 'flex-start',
        cursor: 'pointer',
        transition: 'background 0.5s ease'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={cardContainerStyle}>
                <Box sx={imageContainerStyle} 
                    // onMouseEnter={()=>setForwardArrowHovered(true)} 
                    // onMouseLeave={()=>setForwardArrowHovered(false)} 
                    // onClick={()=>setCloseModal(false)}
                >
                    <img src={personImage} alt={name} style={{width: '256px'}}/>
                </Box>
                <Box sx={infoContainerStyle}>
                    <Box 
                        sx={briefInfoContainerStyle} 
                        // onMouseEnter={()=>setForwardArrowHovered(true)} 
                        // onMouseLeave={()=>setForwardArrowHovered(false)} 
                        // onClick={()=>setCloseModal(false)}
                    >
                        <Box sx={child1Style}>
                            <Box sx={{fontFamily: 'Oxanium-Bold', fontSize: 'clamp(16px, 2vw, 18px)'}}>{name}</Box>
                            <Box sx={{fontFamily: 'NunitoSans_7pt-SemiBold', fontSize: 'clamp(12px, 2vw, 14px)', textTransform: 'none'}}>{designation}</Box>
                        </Box>
                        {/* <Box sx={child2Style}>
                            <img 
                                src={forwardArrowHovered? ForwardArrowBold : ForwardArrowNormal} 
                                alt='forward' 
                                height={32}
                            />
                        </Box> */}
                    </Box>
                    { 
                        mediaIcons &&
                        <Box sx={mediaContainerStyle}>
                            {mediaIcons.map((icons, index)=>(
                                <a
                                    key={index} 
                                    href={icons.link} 
                                    rel='noreferrer'
                                    target='_blank'
                                    onMouseEnter={() => {
                                        if (!isMobile) {
                                          setMediaIconHovered({ ...mediaIconHovered, [index]: 'active' });
                                        }
                                      }}
                                    onMouseLeave={() => {
                                        if (!isMobile) {
                                          setMediaIconHovered({ ...mediaIconHovered, [index]: 'default' });
                                        }
                                      }}
                                    onTouchStart={()=>{setMediaIconHovered({...mediaIconHovered, [index]: 'active'})}}
                                    onTouchEnd={()=>setMediaIconHovered({...mediaIconHovered, [index]: 'default'})}
                                >
                                    <img src={icons.iconVariants[mediaIconHovered[index] || 'default']} alt={icons.title} width={24}/>
                                </a>
                            ))}
                        </Box>
                    }
                </Box>
                <Box 
                    sx={knowMoreBtnStyle}
                    onMouseEnter = {(event) => {
                        event.target.style.backgroundColor = "#232C60"
                        event.target.style.color = "#fff"
                    }}
                    onMouseLeave = {(event) => {
                        event.target.style.backgroundColor = "#fff"
                        event.target.style.color = "#232C60"
                    }}
                    onTouchStart = {(event) => {
                        event.target.style.backgroundColor = "#232C60"
                        event.target.style.color = "#fff"
                    }}
                    onTouchEnd = {(event) => {
                        event.target.style.backgroundColor = "#fff"
                        event.target.style.color = "#232C60"
                    }}
                    onClick={() => setCloseModal(false)}
                >
                    Know More
                </Box>
            </Box>
            {
                !closeModal && 
                // <DropInMotion>
                //     <CustomModal
                //         personImage={personImage}
                //         name={name}
                //         designation={designation}
                //         description={description}
                //         closeModal={closeModal}
                //         setCloseModal={setCloseModal}
                //     />
                // </DropInMotion>
                <CustomModal
                    personImage={personImage}
                    name={name}
                    designation={designation}
                    description={description}
                    closeModal={closeModal}
                    setCloseModal={setCloseModal}
                />
                
            }
        </>
    )
}
// import created modules -------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'
// import { useNavigate } from 'react-router-dom'
// import { useState } from 'react'

// import images ----------------------------------------------------------------
import {
    EnhancedImagingDetail,
    MaterialIdentification,
    NonDestructiveAnalysis,
    HighSpectralResolution
} from '../../assetsv1/Assets'
import CustomIconCardForCustomGPR from '../gprcomponents/CustomIconCardForCustomGPR'
// import NonDestructiveSvgComponent from '../../assetsv1/images/svgicons/gprpageicons/NonDestructiveSvg'


// list-of-icons ----------------------------------------------------------------
const otherIconList = [
    {
        icon: EnhancedImagingDetail,
        headText: [
            'Enhanced',
            'Imaging Detail'
        ],
        description: [
            'Captures a wide range', 
            'of wavelengths, providing', 
            'much more information', 
            'about the imaged surface',
            'than optical imagery'
        ],
        order: 1,
    },
    {
        // icon: HighestResolutionLBandSAR,
        icon: MaterialIdentification,
        headText: ['Material', 'Identification'],
        description: [
            'Enables precise identification', 
            'of materials and their quality', 
            'by their spectral signatures,', 
            'useful in various fields from',
            'agriculture to mineralogy'
        ],
        order: 1,
    },
    {
        // icon: InternationallyAcclaimedExpertiseInSARDesign,
        icon: NonDestructiveAnalysis,
        headText: ['Non-Destructive', 'Analysis'],
        description: [
            'Allows for', 
            'examination of', 
            'objects or scenes', 
            'without altering', 
            'or damaging them', 
        ],
        order: 2,
    },
    {
        // icon: RapidDeploymentAndRealTimeData,
        icon: HighSpectralResolution,
        headText: ['High Spectral', 'Resolution'],
        description: [
            'Offers', 
            'denser data cube', 
            'compared to',
            'multispectral and', 
            'optical imagery' 
        ],
        order: 2,
    }
]

// // function to get the vaertical scrolling zero ---------------------------------
// const scrollToTop =() => {
//     window.scrollTo(0,0);
//   };

// create feature-component of SAR
export default function AdvantagesOfDroneBorneHySpecImaging(){

    // const media-query --------------------------------------------------------
    // mediaquery
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    // const isMatch = useMediaQuery('(max-width: 1024px)')
    // const isMatchToMobile = useMediaQuery('(max-width: 480px)')
    const isMatchToMdTablet = useMediaQuery('(max-width: 600px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')
    // const isMatchToDesktop = useMediaQuery('(max-width: 1920px)')
    // const isMatchToLgDesktop = useMediaQuery('(min-width: 1921px)')
    const isMatchToMdDesktop = useMediaQuery('(max-width: 1180px)')

    // // state variables ----------------------------------------------------------
    // const [knowMoreHovered, setKnowMoreHovered] = useState(false)

    // // navigate hook ------------------------------------------------------------
    // const navigate = useNavigate()
    
    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        width: '100vw',
        height: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#232C60',
        color: '#fff'
    }
    const flexSubContainerStyle = {
        display: 'flex', 
        flexDirection: 'column',
        width: '95%', 
        height: 'auto', 
        justifyContent: 'space-around',
        alignItems: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '52px' : '48px',
        
    }
    const headerTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textTransform: 'uppercase',
        textAlign: 'center'
    }
    const mainElementContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        color: '#fff',
        maxWidth: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    }
    const cardContainerStyle = {
        display: 'flex',
        gap: 'clamp(52px, 2vw, 78px)',
        alignItems: isMatchToMdDesktop? 'center' : 'flex-start',
        justifyContent: isMatchToMdDesktop? 'flex-start' : 'center',
        flexFlow: 'row wrap',
        // flexDirection: isMatchToMdDesktop? 'column' : 'row',
        // width: '100%'
    }
    const childContainerStyle = {
        display: 'flex',
        gap: 'clamp(52px, 2vw, 78px)',
        alignItems: isMatchToMdTablet? 'center' : 'flex-start',
        justifyContent:'center',
        flexWrap: 'wrap',
        flexGrow: 1,
    }
    const knowMoreButtonContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={headerTextContainerStyle}>
                        <p 
                            style={{
                                fontFamily:'Oxanium-Bold',
                                fontSize:  isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px',
                            }}
                        >
                            Advantages of Hyperspectral Imaging
                        </p>
                    </Box>
                    <Box sx={mainElementContainerStyle}>
                        <Box sx={cardContainerStyle}>
                            <Box sx={childContainerStyle}>
                                {
                                    otherIconList.slice(0,2).map((icon, index)=>(
                                        <CustomIconCardForCustomGPR
                                            key={index}
                                            headText={icon.headText}
                                            description={icon.description}
                                            iconWidth={isMatchToLaptop? '72px'  : '96px'}
                                            image={icon.icon}
                                        />
                                        // <CustomIconCardForCustomGPR
                                        //     key={index}
                                        //     headText={icon.headText}
                                        //     description={icon.description}
                                        //     iconWidth={isMatchToLaptop? '72px'  : '96px'}
                                        // ><icon.icon/></CustomIconCardForCustomGPR>
                                    ))
                                }
                            </Box>
                            <Box sx={childContainerStyle}>
                                {
                                    otherIconList.slice(2, 4).map((icon, index)=>(
                                        <CustomIconCardForCustomGPR
                                            key={index}
                                            headText={icon.headText}
                                            description={icon.description}
                                            iconWidth={isMatchToLaptop? '72px'  : '96px'}
                                            image={icon.icon}
                                        />
                                        // <CustomIconCardForCustomGPR
                                        //     key={index}
                                        //     headText={icon.headText}
                                        //     description={icon.description}
                                        //     iconWidth={isMatchToLaptop? '72px'  : '96px'}
                                        // ><icon.icon/></CustomIconCardForCustomGPR>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                
            </Box>
        </>
    )
}
// import node-modules ----------------------------------------------------------
import React from 'react'
import { Helmet } from 'react-helmet-async'

// import created modules -------------------------------------------------------
import PageNotFound from '../errorpagecomponents/PageNotFound'

// import images ----------------------------------------------------------------
import { FavIcon } from '../../assetsv1/Assets'

// create the about us page -----------------------------------------------------
export default function Error(){

    // main layout --------------------------------------------------------------
    return(
        <>
            <Helmet>
                <title>Page Not Found | Sisir Radar</title>
                <link rel="icon" href={FavIcon} />
            </Helmet>
            <PageNotFound/>
        </>
    )
}
// import node-modules ----------------------------------------------------------
import React from "react"
import { Box, useMediaQuery, useTheme } from "@mui/material"

// import created modules -------------------------------------------------------
// import DropOutMotion from "../utils/motioncomponents/DropOutMotion"

// import images ----------------------------------------------------------------
import { 
    TimesOfIndiaFinal,
    IndianExpressFinal,
    IndiaTodayFinal,
    ANIFinal,
    ABPAnandaFinal,
    EconomicTimesFinal,
    WIONFinal,
    NewsXFinal, 
    NewsNationFinal
} from "../../assetsv1/Assets"

// list of out partners ---------------------------------------------------------
const newsChannelList = [
    TimesOfIndiaFinal,
    IndianExpressFinal,
    IndiaTodayFinal,
    ANIFinal,
    ABPAnandaFinal,
    EconomicTimesFinal,
    WIONFinal,
    NewsXFinal, 
    NewsNationFinal
]

// create partners section ------------------------------------------------------
export default function AsSeenOn(){

    // variables ----------------------------------------------------------------
    // mediaquery
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToMobileSm = useMediaQuery('(max-width: 480px)')
    const isMatchToMobile = useMediaQuery('(max-width: 720px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1200px)')

    // styles -------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        backgroundColor: '#fff', 
        justifyContent: 'center',
        alignItems: 'center',
        pt: '8vh',
        pb: '8vh',
        gap: '4vh',
        color: '#FF4F00'
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        backgroundColor: '#fff', 
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4vh',
    }
    const headingStyle = {
        textAlign: 'center',
        fontFamily: 'Oxanium-Bold',
        fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px',
        textTransform: 'uppercase'
    }
    const childContainerStyle = {
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: isMatchToLaptop? '4vw' : '2vw'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <p style={headingStyle}>as seen on</p>
                {/* <DropOutMotion>
                    <p style={headingStyle}>as seen on</p>
                </DropOutMotion> */}
                {
                    isMatchToMobile? (
                        <>
                            <Box sx={flexSubContainerStyle}>
                                <Box sx={childContainerStyle}>
                                    {
                                        newsChannelList.slice(0, 2).map((item, index)=>(
                                            <img key={index} src={item} alt="partner" height={isMatchToMobileSm ? 30 : 40}/>
                                            // <DropOutMotion key={index} delayTime={index*0.2}>
                                            //     <img src={item} alt="partner" height={isMatchToMobileSm ? 30 : 40}/>
                                            // </DropOutMotion>
                                        ))
                                    }
                                </Box>
                                <Box sx={childContainerStyle}>
                                    {
                                        newsChannelList.slice(2, 4).map((item, index)=>(
                                            <img key={index} src={item} alt="partner" height={isMatchToMobileSm ? 30 : 40}/>
                                            // <DropOutMotion key={index} delayTime={(index + 2)*0.2}>
                                            //     <img src={item} alt="partner" height={isMatchToMobileSm ? 30 : 40}/>
                                            // </DropOutMotion>
                                        ))
                                    }
                                </Box>
                                <Box sx={childContainerStyle}>
                                    {
                                        newsChannelList.slice(4, 6).map((item, index)=>(
                                            <img key={index} src={item} alt="partner" height={isMatchToMobileSm ? 30 : 40}/>
                                            // <DropOutMotion key={index} delayTime={(index + 4)*0.2}>
                                            //     <img src={item} alt="partner" height={isMatchToMobileSm ? 30 : 40}/>
                                            // </DropOutMotion>
                                        ))
                                    }
                                </Box>
                                <Box sx={childContainerStyle}>
                                    {
                                        newsChannelList.slice(6, 8).map((item, index)=>(
                                            <img key={index} src={item} alt="partner" height={isMatchToMobileSm ? 30 : 40}/>
                                            // <DropOutMotion key={index} delayTime={(index + 6)*0.2}>
                                            //     <img src={item} alt="partner" height={isMatchToMobileSm ? 30 : 40}/>
                                            // </DropOutMotion>
                                        ))
                                    }
                                </Box>
                                <img src={newsChannelList[8]} alt="parter" height={isMatchToMobileSm ? 30 : 40}/>
                                {/* <DropOutMotion delayTime={8*0.2}>
                                    <img src={newsChannelList[8]} alt="parter" height={isMatchToMobileSm ? 30 : 40}/>
                                </DropOutMotion> */}
                            </Box>
                        </>

                    ) : (
                        isMatchToLaptop ? (
                            <>
                                <Box sx={flexSubContainerStyle}>
                                    <Box sx={childContainerStyle}>
                                        {
                                            newsChannelList.slice(0, 3).map((item, index)=>(
                                                <img key={index} src={item} alt="partner" height={50}/>
                                                // <DropOutMotion key={index} delayTime={index*0.2}>
                                                //     <img src={item} alt="partner" height={50}/>
                                                // </DropOutMotion>
                                            ))
                                        }
                                    </Box>
                                    <Box sx={childContainerStyle}>
                                        {
                                            newsChannelList.slice(3, 6).map((item, index)=>(
                                                <img key={index} src={item} alt="partner" height={50}/>
                                                // <DropOutMotion key={index} delayTime={(index + 3)*0.2}>
                                                //     <img src={item} alt="partner" height={50}/>
                                                // </DropOutMotion>
                                            ))
                                        }
                                    </Box>
                                    <Box sx={childContainerStyle}>
                                        {
                                            newsChannelList.slice(6, 9).map((item, index)=>(
                                                <img key={index} src={item} alt="partner" height={50}/>
                                                // <DropOutMotion key={index} delayTime={(index + 6)*0.2}>
                                                //     <img src={item} alt="partner" height={50}/>
                                                // </DropOutMotion>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </>
                        ) : (
                                <>
                                    <Box sx={flexSubContainerStyle}>
                                        <Box sx={childContainerStyle}>
                                            {
                                                newsChannelList.slice(0, 5).map((item, index)=>(
                                                    <img key={index} src={item} alt="partner" height={60}/>
                                                    // <DropOutMotion key={index} delayTime={index*0.2}>
                                                    //     <img src={item} alt="partner" height={60}/>
                                                    // </DropOutMotion>
                                                ))
                                            }
                                        </Box>
                                        <Box sx={childContainerStyle}>
                                            {
                                                newsChannelList.slice(5, 9).map((item, index)=>(
                                                    <img key={index} src={item} alt="partner" height={60}/>
                                                    // <DropOutMotion key={index} delayTime={(index + 5)*0.2}>
                                                    //     <img src={item} alt="partner" height={60}/>
                                                    // </DropOutMotion>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </>
                            )
                    )
                }
            </Box>
        </>
    )
}

// import created modules -------------------------------------------------------
import {
    Box,
    useMediaQuery,
} from '@mui/material'
// import { useMotionValue, useTransform, motion } from 'framer-motion'

// import images ----------------------------------------------------------------
import { whatIsHySpecBgImageLg, WhatIsHySpecImage } from '../../assetsv1/Assets'

// Hyspec description --------------------------------------------------------------
const HyspecDescription = [
    'Hyperspectral imaging is a powerful technology that collects and processes information in the Visible and IR region of the electromagnetic spectrum for each pixel in an image.',
    'It can collect more than 20 narrow spectral bands.',
    'Hyperspectral’s non-invasive and non-destructive capabilities in object identification and quality analysis unlocks unique applications in remote sensing.',
    'Sisir Radar offers proprietary single-pixel classification and signature extraction for seamless object identification using Hyperspectral data.',
    ]

// create definition-of-hyspec component -------------------------------------------
export default function WhatIsHyspec(){

    // variables ----------------------------------------------------------------
    // const theme & media-query 
    const isMatchToLgTabletSm = useMediaQuery('(max-width: 760px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLgTablet = useMediaQuery('(max-width: 1350px)')
    const isMatchToLgTablet2 = useMediaQuery('(max-width: 1150px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')
    // const isMatchToMobileSm = useMediaQuery('(max-width: 480px)')

    // motion variables
    // const x = useMotionValue(0)
    // const y = useMotionValue(0)
    // const rotateX = useTransform(y, [-100, 100], [-50, 50])
    // const rotateY = useTransform(x, [100, -100], [50, -50])

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        // backgroundColor: '#F7F5F5',
        width: '100vw',
        height: 'fit-content',
        // flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
        // wrap: 'wrap',
        background: `url(${whatIsHySpecBgImageLg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }
    const headerTextContainerStyle = {
        display: 'flex',
        color: '#fff',
        maxWidth: '80vw',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontFamily: 'Oxanium-Bold',
        mb: '16px',
        // mr: '40px'
    }
    // const hyspecImageContainerStyle = {
    //     maxWidth: '700px', 
    //     minWidth: '250px', 
    //     display: 'flex', 
    //     flexShrink: 1.4,
    //     borderRadius: '10px'
    // }
    const descriptionTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        maxWidth: '700px',
        // minWidth: '300px',
        alignItems: 'flex-start',
        fontSize: isMatchToLgTabletSm ? '12px' : '16px',
        fontFamily: 'NunitoSans_7pt-Light',
    }

    const headingStyle = {
        marginLeft: '20px',
        width: '100%',
        // textAlign: 'center',
        fontFamily: 'Oxanium-Bold',
        // fontSize: isMatchToMobileSm? '28px' : '40px',
        textTransform: 'uppercase',
        color: '#fff',
        fontSize: isMatchToLgTabletSm ? '16px' : '24px',
        // fontSize: 'clamp(24px, 3vw, 40px)',
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        alignitems: 'center',
        justifyContent: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '64px' : '78px',
        perspective: 2000,
    }
    const child1Style = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: isMatchToTablet? '52px' : isMatchToLgTablet? '64px' : '48px',
    }
    const grndChildStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: isMatchToTablet? '70vw' : isMatchToLgTablet2? '50%' : '40%',
        justifyContent: 'space-between',
        // fontSize: 'clamp(14px, 2vw, 16px)',
        color: '#232C60',
        cursor: 'pointer'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                <Box sx={headerTextContainerStyle}>
                                    <p style={headingStyle}>what is hyperspectral imaging?</p>
                                </Box>
                                <Box sx={descriptionTextContainerStyle}>
                                    <ul className='hyspec-description' style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                        {HyspecDescription.map((item, index)=>(
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </Box>   
                            </Box> 
                        </Box>
                        <Box style={grndChildStyle}>
                            <img src={WhatIsHySpecImage} alt='hyspec payload' style={{width: isMatchToLgTablet? '70%' : '40%', alignSelf: 'center' }}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
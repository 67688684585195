// import created modules -------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'
// import { useNavigate } from 'react-router-dom'
// import { useState } from 'react'

// import images ----------------------------------------------------------------
import {
    NonDestructiveTesting,
    SubSurfaceTerrainInterpretation,
    PrecisePositioning,
    ImmediateTracking
} from '../../assetsv1/Assets'
import CustomIconCardForCustomGPR from './CustomIconCardForCustomGPR'
// import NonDestructiveSvgComponent from '../../assetsv1/images/svgicons/gprpageicons/NonDestructiveSvg'


// list-of-icons ----------------------------------------------------------------
const otherIconList = [
    {
        icon: NonDestructiveTesting,
        headText: [
            'Non-Destructive',
            'Testing'
        ],
        description: [
            'GPR “sees” underground ', 
            'without harming', 
            'structures underneath', 
            'unlike digging'
        ],
        order: 1,
    },
    {
        // icon: HighestResolutionLBandSAR,
        icon: SubSurfaceTerrainInterpretation,
        headText: ['Sub-surface', 'terrain interpretation'],
        description: [
            'Sophisticated processing', 
            'capabilities help identify', 
            'objects and material', 
            'transitions underground'
        ],
        order: 1,
    },
    {
        // icon: InternationallyAcclaimedExpertiseInSARDesign,
        icon: PrecisePositioning,
        headText: ['Precise', 'Positioning'],
        description: [
            'Locate regions', 
            'underground with accurate', 
            'estimate of position, depth', 
            'and dimensions'
        ],
        order: 2,
    },
    {
        // icon: RapidDeploymentAndRealTimeData,
        icon: ImmediateTracking,
        headText: ['Immediate', 'Tracking'],
        description: [
            'Get real-time', 
            '3D mapping', 
            'of the ', 
            'underground'
        ],
        order: 2,
    }
]

// // function to get the vaertical scrolling zero ---------------------------------
// const scrollToTop =() => {
//     window.scrollTo(0,0);
//   };

// create feature-component of SAR
export default function AdvantagesOfCustomGPR(){

    // const media-query --------------------------------------------------------
    // mediaquery
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    // const isMatch = useMediaQuery('(max-width: 1024px)')
    // const isMatchToMobile = useMediaQuery('(max-width: 480px)')
    const isMatchToMdTablet = useMediaQuery('(max-width: 600px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')
    // const isMatchToDesktop = useMediaQuery('(max-width: 1920px)')
    // const isMatchToLgDesktop = useMediaQuery('(min-width: 1921px)')
    const isMatchToMdDesktop = useMediaQuery('(max-width: 1180px)')

    // // state variables ----------------------------------------------------------
    // const [knowMoreHovered, setKnowMoreHovered] = useState(false)

    // // navigate hook ------------------------------------------------------------
    // const navigate = useNavigate()
    
    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        width: '100vw',
        height: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#232C60',
        color: '#fff'
    }
    const flexSubContainerStyle = {
        display: 'flex', 
        flexDirection: 'column',
        width: '95%', 
        height: 'auto', 
        justifyContent: 'space-around',
        alignItems: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '52px' : '48px',
        
    }
    const headerTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textTransform: 'uppercase',
        textAlign: 'center'
    }
    const mainElementContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        color: '#fff',
        maxWidth: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    }
    const cardContainerStyle = {
        display: 'flex',
        gap: 'clamp(52px, 2vw, 78px)',
        alignItems: isMatchToMdDesktop? 'center' : 'flex-start',
        justifyContent: isMatchToMdDesktop? 'flex-start' : 'center',
        flexFlow: 'row wrap',
        // flexDirection: isMatchToMdDesktop? 'column' : 'row',
        // width: '100%'
    }
    const childContainerStyle = {
        display: 'flex',
        gap: 'clamp(52px, 2vw, 78px)',
        alignItems: isMatchToMdTablet? 'center' : 'flex-start',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexGrow: 1,
    }
    // const knowMoreButtonContainerStyle = {
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     width: '100%'
    // }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={headerTextContainerStyle}>
                        <p 
                            style={{
                                fontFamily:'Oxanium-Bold',
                                fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px',
                            }}
                        >
                            Advantages of custom GPR
                        </p>
                    </Box>
                    <Box sx={mainElementContainerStyle}>
                        <Box sx={cardContainerStyle}>
                            <Box sx={childContainerStyle}>
                                {
                                    otherIconList.slice(0,2).map((icon, index)=>(
                                        <CustomIconCardForCustomGPR
                                            key={index}
                                            headText={icon.headText}
                                            description={icon.description}
                                            iconWidth={isMatchToLaptop? '96px'  : '128px'}
                                            image={icon.icon}
                                        />
                                        // <CustomIconCardForCustomGPR
                                        //     key={index}
                                        //     headText={icon.headText}
                                        //     description={icon.description}
                                        //     iconWidth={isMatchToLaptop? '96px'  : '128px'}
                                        // ><icon.icon/></CustomIconCardForCustomGPR>
                                    ))
                                }
                            </Box>
                            <Box sx={childContainerStyle}>
                                {
                                    otherIconList.slice(2, 4).map((icon, index)=>(
                                        <CustomIconCardForCustomGPR
                                            key={index}
                                            headText={icon.headText}
                                            description={icon.description}
                                            iconWidth={isMatchToLaptop? '96px'  : '128px'}
                                            image={icon.icon}
                                        />
                                        // <CustomIconCardForCustomGPR
                                        //     key={index}
                                        //     headText={icon.headText}
                                        //     description={icon.description}
                                        //     iconWidth={isMatchToLaptop? '96px'  : '128px'}
                                        // ><icon.icon/></CustomIconCardForCustomGPR>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                    {/* <Box sx={knowMoreButtonContainerStyle}>
                        <Box 
                            sx={{
                            backgroundColor: (knowMoreHovered)? 'transparent' : '#232C60', 
                            color: (knowMoreHovered)?'#232C60':'#fff',
                            border: 'solid #232C60',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            padding: '8px 18px',
                            fontFamily: 'PromptBold1',
                            cursor: 'pointer'
                            }}
                            onMouseEnter={(()=>setKnowMoreHovered(true))}
                            onMouseLeave={()=>setKnowMoreHovered(false)}
                            onClick={()=>{navigate('technology'); scrollToTop()}}
                        >
                            KNOW MORE ABOUT SAR
                        </Box>
                    </Box> */}
                </Box>
                
            </Box>
        </>
    )
}
// import node-modules ----------------------------------------------------------
import React from "react"
import { Box, useTheme, useMediaQuery } from "@mui/material"

// import created modules -------------------------------------------------------
import DropOutMotion from "../utils/motioncomponents/DropOutMotion"

// import images ----------------------------------------------------------------
import { 
    IDEXFinalLogo,
    MinistryOfDefenceIndia1,
    DefenceResearchAndDevelopmentOrganisationLogo,
    BadgeOfTheIndianAirForce,
    VedantaLogo,
    HZLLogo,
    ClientKMDALogo1,
    FORGE,
    IITKharagpurLogo 
} from "../../assetsv1/Assets"

// list of out partners ---------------------------------------------------------
const partnersList = [
    IDEXFinalLogo,
    MinistryOfDefenceIndia1,
    DefenceResearchAndDevelopmentOrganisationLogo,
    BadgeOfTheIndianAirForce,
    VedantaLogo,
    HZLLogo,
    ClientKMDALogo1,
    FORGE,
    IITKharagpurLogo
]

// create partners section ------------------------------------------------------
export default function Partners(){

    // variables ----------------------------------------------------------------
    // mediaquery
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToMobileSm = useMediaQuery('(max-width: 480px)')
    const isMatchToMobile = useMediaQuery('(max-width: 720px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1200px)')

    // styles -------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        backgroundColor: '#fff', 
        justifyContent: 'center',
        alignItems: 'center',
        pt: '8vh',
        pb: '8vh',
        gap: '4vh',
        color: '#FF4F00'
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        backgroundColor: '#fff', 
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4vh',
    }
    const headingStyle = {
        textAlign: 'center',
        fontFamily: 'Oxanium-Bold',
        fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px',
        textTransform: 'uppercase'
    }
    const childContainerStyle = {
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: isMatchToLaptop? '4vw' : '2vw'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <DropOutMotion>
                    <p style={headingStyle}>our partners</p>
                </DropOutMotion>
                {
                    isMatchToMobile? (
                        <>
                            <Box sx={flexSubContainerStyle}>
                                <Box sx={childContainerStyle}>
                                    {
                                        partnersList.slice(0, 2).map((item, index)=>(
                                            <DropOutMotion key={index} delayTime={index*0.05}>
                                                <img src={item} alt="partner" height={isMatchToMobileSm ? 40 : 60}/>
                                            </DropOutMotion>
                                        ))
                                    }
                                </Box>
                                <Box sx={childContainerStyle}>
                                    {
                                        partnersList.slice(2, 4).map((item, index)=>(
                                            <DropOutMotion key={index} delayTime={(index + 2)*0.05}>
                                                <img src={item} alt="partner" height={isMatchToMobileSm ? 40 : 60}/>
                                            </DropOutMotion>
                                        ))
                                    }
                                </Box>
                                <Box sx={childContainerStyle}>
                                    {
                                        partnersList.slice(4, 6).map((item, index)=>(
                                            <DropOutMotion key={index} delayTime={(index + 4)*0.05}>
                                                <img src={item} alt="partner" height={isMatchToMobileSm ? 40 : 60}/>
                                            </DropOutMotion>
                                        ))
                                    }
                                </Box>
                                <Box sx={childContainerStyle}>
                                    {
                                        partnersList.slice(6, 8).map((item, index)=>(
                                            <DropOutMotion key={index} delayTime={(index + 6)*0.05}>
                                                <img src={item} alt="partner" height={isMatchToMobileSm ? 40 : 60}/>
                                            </DropOutMotion>
                                        ))
                                    }
                                </Box>
                                <DropOutMotion delayTime={8*0.05}>
                                    <img src={partnersList[8]} alt="parter" height={isMatchToMobileSm ? 40 : 60}/>
                                </DropOutMotion>
                            </Box>
                        </>

                    ) : (
                        isMatchToLaptop ? (
                            <>
                                <Box sx={flexSubContainerStyle}>
                                    <Box sx={childContainerStyle}>
                                        {
                                            partnersList.slice(0, 3).map((item, index)=>(
                                                <DropOutMotion key={index} delayTime={index*0.1}>
                                                    <img src={item} alt="partner" height={70}/>
                                                </DropOutMotion>
                                            ))
                                        }
                                    </Box>
                                    <Box sx={childContainerStyle}>
                                        {
                                            partnersList.slice(3, 6).map((item, index)=>(
                                                <DropOutMotion key={index} delayTime={(index + 3)*0.1}>
                                                    <img src={item} alt="partner" height={70}/>
                                                </DropOutMotion>
                                            ))
                                        }
                                    </Box>
                                    <Box sx={childContainerStyle}>
                                        {
                                            partnersList.slice(6, 9).map((item, index)=>(
                                                <DropOutMotion key={index} delayTime={(index + 6)*0.1}>
                                                    <img src={item} alt="partner" height={70}/>
                                                </DropOutMotion>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </>
                        ) : (
                                <>
                                    <Box sx={flexSubContainerStyle}>
                                        <Box sx={childContainerStyle}>
                                            {
                                                partnersList.slice(0, 5).map((item, index)=>(
                                                    <DropOutMotion key={index} delayTime={index*0.1}>
                                                        <img src={item} alt="partner" height={80}/>
                                                    </DropOutMotion>
                                                ))
                                            }
                                        </Box>
                                        <Box sx={childContainerStyle}>
                                            {
                                                partnersList.slice(5, 9).map((item, index)=>(
                                                    <DropOutMotion key={index} delayTime={(index + 5)*0.1}>
                                                        <img src={item} alt="partner" height={80}/>
                                                    </DropOutMotion>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </>
                            )
                    )
                }
            </Box>
        </>
    )
}

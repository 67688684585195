// import created modules -------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import images ----------------------------------------------------------------
import { SARRangeAndCoverageLg, SARRangeAndCoverageSm } from '../../assetsv1/Assets'

// create definition-of-sar component -------------------------------------------
export default function SARRangeAndCoverage(){

    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        backgroundColor: '#fff',
        width: '100vw',
        height: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        wrap: 'wrap',
    }
    const flexSubContainerStyle = {
        display: 'flex', 
        flexDirection: 'column',
        width: '90%', 
        height: 'auto', 
        justifyContent: 'space-evenly',
        alignItems: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '52px' : '48px',
    }
    const headerTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        color: '#232C60',
        maxWidth: '80%',
        alignItems: 'center',
        textTransform: 'uppercase',
        textAlign: 'center'
    }
    const sarImageContainerStyle = {
        maxWidth: '875px', 
        minWidth: '250px', 
        display: 'flex', 
        flexShrink: 1.4,
        borderRadius: '10px'
    }
    const descriptionTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        color: '#232C60',
        maxWidth: '875px',
        minWidth: '300px',
        alignItems: 'flex-start',
        fontSize: 'clamp(16px, 2vw, 18px)',
        fontFamily: 'NunitoSans_7pt-Regular',
        // fontWeight: '600'
    }
    
    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={headerTextContainerStyle}>
                        <p 
                            style={{
                                fontFamily:'Oxanium-Bold', 
                                textTransform: 'uppercase', 
                                fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px',
                            }}
                        >
                            sar range and coverage
                        </p>
                    </Box>
                    <Box sx={descriptionTextContainerStyle}>
                        The side-looking capability of SAR helps you see much further than camera-based technologies. This also helps capture significantly more area.
                    </Box>
                    <Box sx={sarImageContainerStyle}>
                        <img src={isMatchToSmallDevice? SARRangeAndCoverageSm : SARRangeAndCoverageLg} alt='SAR' style={{width: '100%',}}/>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
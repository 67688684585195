// import node-modules ----------------------------------------------------------
import {
    Box,
} from '@mui/material'

// create custom-cards for founders and advisors as well ------------------------
export default function CustomIconCard({icon, description, subDescription, iconWidth, cardWidth, fontSize, subFontSize, textTransform}){

    // style --------------------------------------------------------------------
    const cardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
    }
    const iconContainerStyle = {
        display: 'flex',
        width: 'fit-content',
        height: 'fit-content',
    }
    const infoContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        // textTransform: 'capitalize'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={cardContainerStyle}>
                <Box sx={iconContainerStyle}>
                    <img src={icon} alt='icon' style={{width: iconWidth}}/>
                </Box>
                <Box sx={infoContainerStyle}>
                    <Box sx={{fontFamily: 'NunitoSans_7pt-Regular', width: cardWidth, fontSize: fontSize, textTransform: 'capitalize'}}>
                        {
                            description.map((body, bodyIndex)=>(
                                <Box 
                                    key={bodyIndex}
                                >
                                    {body}
                                </Box>
                            ))
                        }
                        {
                            subDescription &&  
                            <Box sx={{fontFamily: 'NunitoSans_7pt-Light', width: cardWidth, fontSize: subFontSize, textTransform: textTransform ? textTransform : 'capitalize'}}>
                                {
                                    subDescription.map((body, bodyIndex)=>(
                                        <Box 
                                            key={bodyIndex}
                                        >
                                            {body}
                                        </Box>
                                    ))
                                }
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}
// import node-modules ----------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery,
} from '@mui/material'

// import images ----------------------------------------------------------------
// import {
//     SoumyaMisra,
//     TapanMisra,
//     UrmiBhambhani,
//     Facebook1,
//     Facebook2,
//     Instagram1,
//     Instagram2,
//     Linkedin1,
//     Linkedin2,
//     X1,
//     X2,
// } from '../../assets/Assets'
import { 
    SoumyaMisra,
    TapanMisra,
    UrmiBhambhani, 
    Facebook1F,
    Facebook2F,
    LinkedIn1F,
    LinkedIn2F,
    Instagram1F,
    Instagram2F,
    X1F,
    X2F
} from '../../assetsv1/Assets'

// import created modules -------------------------------------------------------
import CustomCard from './CustomCard'

// list of founders -------------------------------------------------------------
const foundersList = [
    {
        name: 'DR TAPAN MISRA',
        designation: 'Founder and Chairman',
        personImage: TapanMisra,
        mediaIcons: [
            {
                title: 'linked-in',
                iconVariants: {
                    default: LinkedIn2F,
                    active: LinkedIn1F,
                },
                link: 'https://in.linkedin.com/in/tapan-misra-2a6ba85?trk=people-guest_people_search-card&challengeId=AQHw0Iigjd7i-QAAAYrML_5xoHcXaR8c8dOT19laXqh45Oh0NztJDlpV5TPn5UzCsyAFYLis4N-5N9zW5hF_33vfKrPuaMe5dw&submissionId=1e355d40-2722-8817-aca9-6803eb118d13&challengeSource=AgGOagEXgFv87gAAAYrMMD0WU_53oaqcLaodFfu4pu1SjxVsIyiO0fB6PfFhO_Q&challegeType=AgGo6aOutl8wPQAAAYrMMD0YQ_vnzGydgBJhbLnsfKdcPXksu4W2mV8&memberId=AgFN8jcIFVMdfAAAAYrMMD0bB4vfBg4b5N-Su1_uOIrXKzs&recognizeDevice=AgH-CvzhKmE7TAAAAYrMMD0dmdBVJHPIyamDeBrnAnd1tfs7Zj8u'
            },
            {
                title: 'twitter',
                iconVariants: {
                    default: X2F,
                    active: X1F,
                },
                link: 'https://twitter.com/TapanMisra1'
            },
            {
                title: 'facebook',
                iconVariants: {
                    default: Facebook2F,
                    active: Facebook1F,
                },
                link: 'https://www.facebook.com/tapanmisra'
            },
            {
                title: 'insta',
                iconVariants: {
                    default: Instagram2F,
                    active: Instagram1F,
                },
                link: 'https://www.instagram.com/iamtapanmisra/'
            },
        ],
        description: [
            {
                mainText: 'Four decades of experience in Space-Tech and Radar Systems. One of the world’s foremost experts in Synthetic Aperture Radar (SAR) Technology. Indigenously designed and spearheaded RISAT - India’s first cutting edge SAR-based reconnaissance satellite.',
                subText: [
                    'Pioneered development of indigenous SAR technology and processing in India (after USA embargoed India following Pokhran-2)',
                    'Former Director, Physical Research Laboratory',
                    'Built SAR payloads in L, S, C, X bands for both Airborne and Space-borne SAR',
                    'Led RISAT series, Chandrayaan-2 dual frequency SAR, NISAR, Hyperspectral Imaging Technology Development & HTS Communication Satellite',
                    '8 granted patents (SAR systems, SAR Processing, NF Measurement, MW Measurement, Hyperspectral compression cum single pixel Classification)',
                    '5 copyrights (SAR Processing, MW Measurement) and 50+ papers',
                    'FNAE, Core Member IAA, Fellow IETE, Fellow ISRS',
                    'Adjunct Professor, IIT Jodhpur',
                    'Former Adjunct Professor, IIT Kharagpur',
                    'Experienced in building product delivery oriented R&D organisations',
                    'Experience in building large scale projects from scratch to final delivery',
                ],
            },
        ],
    },
    {
        name: 'SOUMYA MISRA',
        designation: 'Co-Founder and CEO',
        personImage: SoumyaMisra,
        mediaIcons: [
            {
                title: 'linked-in',
                iconVariants: {
                    default: LinkedIn2F,
                    active: LinkedIn1F,
                },
                link: 'https://in.linkedin.com/in/soumya-tapan-misra?challengeId=AQF3hNvaU5o4OwAAAYr0fLFtTRFcxmYjiP3PJ1eehmtTREtZk_tp_dXDSx55JnvAqIFglhYIwOl3Cyu6ns-pe8RjHpetfxhgFg&submissionId=441f4796-1389-8a17-09a2-3e28357c4403&challengeSource=AgEOn5gyprT56wAAAYr0fO6n3RtgAO6xTPNZttypRhSnhGxwAgSU6pzZGkKpWbA&challegeType=AgH4QeWE2Z_jPAAAAYr0fO6rBwrg-Y1SVCr27M0F-IvSW2v7lijUNWk&memberId=AgGCVQBsm2qVSAAAAYr0fO6u_ZT8c2hu4UA0nN7V6OZXlD8&recognizeDevice=AgEQH8qwVxq0VQAAAYr0fO6ynh2j0-0op49vpqc4ZZicM9W7prep'
            },
        ],
        description: [
            {
                mainText: '',
                subText: [
                    'Expertise in Hardware Design, Miniaturization, Image Processing',
                    'Led smartphone design at a Sequoia-funded startup (Creo, acquired by Hike)',
                    'Selected by GOI as one of Top 50 mobile phone designers in India to work with Mediatek in Taiwan',
                    'Designed GPUs at Intel',
                    'Multiple papers in nanoelectronics in international journals',
                    'Founder & CEO, Summachar - an infographic news platform for Gen Z',
                ],
            },
        ],
    },
    {
        name: 'URMI BHAMBHANI',
        designation: 'Co-Founder and CTO',
        personImage: UrmiBhambhani,
        mediaIcons: [
            {
                title: 'linked-in',
                iconVariants: {
                    default: LinkedIn2F,
                    active: LinkedIn1F,
                },
                link: 'https://in.linkedin.com/in/urmi-bhambhani-misra?trk=people-guest_people_search-card'
            },
        ],
        description: [
            {
                mainText: '',
                subText: [
                    'Expertise in Deep Learning, Machine Learning, Distributed Systems',
                    'Built complex large-scale distributed operating systems at NetApp',
                    'Led the development of NLP-based AI products at Educational Initiatives',
                    '1 pending patent',
                    'Technical review committee member of AnitaB.org Grace Hopper Celebration, India',
                    'Founder & CTO, Summachar',
                ],
            },
        ],
    }
]

// create the founder section ---------------------------------------------------
export default function Founders(){

    // const theme & media-query ------------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        backgroundColor: '#232C60',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        font: 'prompt',
        wrap: 'wrap',
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '95%',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '52px' : '48px',
    }
    const child1Style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        fontSize: '2rem',
        fontStyle: 'bold'
    }
    const child2Style = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        flexFlow: 'wrap',
        gap: '1.5rem',
        minWidth: '350px'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
        <Box sx={flexMainContainerStyle}>
            <Box sx={flexSubContainerStyle}>
                <Box sx={child1Style}>
                    <p 
                        style={{
                            fontFamily:'Oxanium-Bold', 
                            textTransform: 'uppercase', 
                            fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px' 
                        }}
                    >
                        Founders
                    </p>
                </Box>
                <Box sx={child2Style}>
                    {
                        foundersList.map((founder, index)=>(
                            <CustomCard 
                                key={index}
                                name={founder.name}
                                personImage={founder.personImage} 
                                designation={founder.designation}
                                mediaIcons={founder.mediaIcons}
                                description={founder.description}
                            />
                            // <FadeInMotion key={index} durationTime={0.5} delayTime={index * 0.5}>
                            //     <CustomCard 
                            //         name={founder.name}
                            //         personImage={founder.personImage} 
                            //         designation={founder.designation}
                            //         mediaIcons={founder.mediaIcons}
                            //         description={founder.description}
                            //     />
                            // </FadeInMotion>
                        ))
                    }
                </Box>
            </Box>
        </Box>
        </>
    )
}
// import created modules -------------------------------------------------------
import {
    Box,
    useMediaQuery,
} from '@mui/material'
import { useMotionValue, useTransform, motion } from 'framer-motion'

// import images ----------------------------------------------------------------
// import { 
//     SARData, 
// } from '../../assets/Assets'
import { CompanyGoalBgImageLg, SARPayload } from '../../assetsv1/Assets'

// import { TextMotionOnWords, TextMotionOnCharacters } from '../utils/motioncomponents/TextMotion'

// SAR description --------------------------------------------------------------
const SARDescription = [
    'Synthetic Aperture Radar is a form of radar that is used to create high-resolution 2D images or 3D reconstructions of landscapes.',
    'Radar Imagery gives detailed information on the physical properties of objects in the scene.',
    'SAR is typically mounted on a moving platform, such as an aircraft or spacecraft.',
    'As the radar antenna moves over the target area, a very coarse resolution (100s of metres) data is collected at a high sampling rate. Computational signal processing is done over the dataset thus captured to provide extremely fine resolution (in metres or sub-metric order of magnitude) composite radar imagery of the target area.',
    "SAR’s main use is remote sensing i.e. detecting and monitoring the physical characteristics of an area by measuring its scattered radiation at a distance."
]

// create definition-of-sar component -------------------------------------------
export default function WhatIsSAR(){

    // variables ----------------------------------------------------------------
    // const theme & media-query 
    const isMatchToLgTabletSm = useMediaQuery('(max-width: 760px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLgTablet = useMediaQuery('(max-width: 1350px)')
    const isMatchToLgTablet2 = useMediaQuery('(max-width: 1150px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')
    const isMatchToMobileSm = useMediaQuery('(max-width: 480px)')

    // motion variables
    const x = useMotionValue(0)
    const y = useMotionValue(0)
    const rotateX = useTransform(y, [-100, 100], [-50, 50])
    const rotateY = useTransform(x, [100, -100], [50, -50])

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        // backgroundColor: '#F7F5F5',
        width: '100vw',
        height: 'fit-content',
        // flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
        // wrap: 'wrap',
        background: `url(${CompanyGoalBgImageLg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }
    const headerTextContainerStyle = {
        display: 'flex',
        color: '#fff',
        maxWidth: isMatchToMobileSm ? '90%' : '80%',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontFamily: 'Oxanium-Bold',
        mb: '16px',
        // textAlign: isMatchToMobileSm ? 'center' : ''
    }
    // const sarImageContainerStyle = {
    //     maxWidth: '700px', 
    //     minWidth: '250px', 
    //     display: 'flex', 
    //     flexShrink: 1.4,
    //     borderRadius: '10px'
    // }
    const descriptionTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        maxWidth: '700px',
        // minWidth: '300px',
        alignItems: 'flex-start',
        fontSize: isMatchToLgTabletSm ? '12px' : '16px',
        fontFamily: 'NunitoSans_7pt-Light',
    }

    const headingStyle = {
        marginLeft: isMatchToMobileSm? '0px' : '20px',
        width: '100%',
        // textAlign: 'center',
        fontFamily: 'Oxanium-Bold',
        // fontSize: isMatchToMobileSm? '28px' : '40px',
        textTransform: 'uppercase',
        color: '#fff',
        fontSize: isMatchToMobileSm ? '18px' : '24px',
        textAlign: isMatchToMobileSm ? 'center' : ''
        // fontSize: 'clamp(24px, 3vw, 40px)',
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        alignitems: 'center',
        justifyContent: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '64px' : '78px',
        perspective: 2000,
    }
    const child1Style = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: isMatchToTablet? '52px' : isMatchToLgTablet? '64px' : '48px',
    }
    const grndChildStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: isMatchToTablet? '70vw' : isMatchToLgTablet2? '50%' : '40%',
        justifyContent: 'space-between',
        // fontSize: 'clamp(14px, 2vw, 16px)',
        color: '#232C60',
        cursor: 'pointer'
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                {/* <Box sx={flexSubContainerStyle}>
                    <Box sx={headerTextContainerStyle}>
                        <p style={{fontFamily:'NulshockBd', textTransform: 'uppercase', fontSize: 'clamp(24px, 3vw, 40px)'}}>what is sar?</p>
                    </Box>
                    <Box sx={sarImageContainerStyle}>
                        <img src={SARData} alt='SAR' style={{width: '100%',}}/>
                    </Box>
                    <Box sx={descriptionTextContainerStyle}>
                        <ul className='sar-description' style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            {SARDescription.map((item, index)=>(
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </Box>
                </Box> */}
                <Box sx={flexSubContainerStyle}>
                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                <Box sx={headerTextContainerStyle}>
                                    <p style={headingStyle}>what is Synthetic Aperture Radar?</p>
                                </Box>
                                <Box sx={descriptionTextContainerStyle}>
                                    <ul 
                                        className='sar-description' 
                                        style={{ 
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            gap: '8px'
                                        }}
                                    >
                                        {SARDescription.map((item, index)=>(
                                            <li key={index}>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </Box>   
                            </Box> 
                        </Box>
                        <motion.div 
                            style={{
                                x, y, rotateX, rotateY, z: 1000,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'auto',
                                width: isMatchToTablet? '70vw' : isMatchToLgTablet2? '50%' : '40%',
                                justifyContent: 'space-between',
                                color: '#232C60',
                                cursor: 'grab'
                            }}
                            drag
                            dragElastic={0.18}
                            dragConstraints={{top: 0, left: 0, right: 0, bottom: 0}}
                            whileTap={{cursor: 'grabbing'}}
                        >
                            <img src={SARPayload} alt='SAR payload' style={{width: isMatchToLgTablet? '90%' : '80%', alignSelf: 'center' }}/>
                        </motion.div>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
// import node-modules ----------------------------------------------------------
import React from "react"
import { 
    Box, 
    // useTheme, 
    useMediaQuery } from "@mui/material"

// import created modules -------------------------------------------------------
import DropRightMotion from '../utils/motioncomponents/DropRightMotion'
import DropLeftMotion from "../utils/motioncomponents/DropLeftMotion"
import DropInMotion from "../utils/motioncomponents/DropInMotion"
import DropOutMotion from "../utils/motioncomponents/DropOutMotion"

// import images ----------------------------------------------------------------
import { 
    Certificate1, 
    Certificate2, 
    IdexAwardReceiving,
    IdexAwardReceivingBgImage,
    BadgeOfTheIndianAirForce
} from "../../assetsv1/Assets"

// create idex-award-receiving section ------------------------------------------
export default function IdexAwardReceivingSection(){
    // variables ----------------------------------------------------------------
    const isMatchToTabletSm = useMediaQuery('(max-width: 880px)')
    const isMatchToTablet = useMediaQuery('(max-width: 1040px)')
    const  isMatchToTablet2 = useMediaQuery('(max-width: 1400px)')
    const isMatchToMobileSm = useMediaQuery('(max-width: 480px)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: 'fit-content',
        background: `url(${IdexAwardReceivingBgImage})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4vh',
        pt: '6vh',
        pb: '6vh',
        color: '#fff',
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: isMatchToTabletSm ? '95vw' : '100vw',
        height: 'fit-content',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4vh',
        color: '#fff',
        mt: '6vh',
        mb: '6vh',
        ml: '2vw',
        mr: '2vw',
    }
    const awradSectionStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2vw'
    }

    // main-layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                {
                    isMatchToTabletSm ? (
                        <>
                            <Box sx={flexSubContainerStyle}>
                                <DropOutMotion>
                                    <img src={BadgeOfTheIndianAirForce} alt="Badge of Indian Air Force" height={isMatchToMobileSm? 200 : 250}/>
                                </DropOutMotion>
                                <DropOutMotion>
                                    <p 
                                        style={{
                                            fontFamily: 'Oxanium-Bold',
                                            fontSize: isMatchToMobileSm? '16px' : '22px', 
                                            textTransform: 'uppercase',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {
                                            isMatchToTabletSm ? ( 
                                                <span>
                                                    we are the proud winners of the idex challenge for developing sar satellite payloads for the indian air force
                                                </span>
                                            
                                            ) : (
                                                <div 
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column', 
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <span>
                                                        we are the proud winners of the idex challenge for 
                                                    </span>
                                                    <span>
                                                        developing sar satellite payloads for the indian air force
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </p>
                                </DropOutMotion>
                                <DropOutMotion>
                                    <img src={IdexAwardReceiving} alt="Soumya Misra is Receiving the Idex Award" height={isMatchToMobileSm? 200 : 250}/>
                                </DropOutMotion>
                                <DropOutMotion>
                                    <p
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            fontFamily: 'NunitoSans_7pt-BoldItalic',
                                            fontSize: isMatchToMobileSm? '12px' : '16px',
                                            // textTransform: 'capitalize',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <span>
                                            Sisir Radar CEO Soumya Misra receiving the
                                        </span>
                                        <span>
                                            award from Air Marshal Surat Singh of the IAF
                                        </span>
                                    </p>
                                </DropOutMotion>
                                <DropOutMotion>
                                    <img src={Certificate1} alt="Certificate 1" height={isMatchToMobileSm? 200 : 250}/>
                                </DropOutMotion>
                                <DropOutMotion>
                                    <img src={Certificate2} alt="Certificate 2" height={isMatchToMobileSm? 200 : 250}/>
                                </DropOutMotion>
                                
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box sx={flexSubContainerStyle}>
                                <DropInMotion>
                                    <img src={BadgeOfTheIndianAirForce} alt="Badge of Indian Air Force" height={isMatchToTablet? 150 : isMatchToTablet2 ? 200 : 250}/>
                                </DropInMotion>
                                <DropInMotion>
                                    <p 
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            fontFamily: 'Oxanium-Bold',
                                            fontSize: '22px', 
                                            textTransform: 'uppercase',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <span>
                                            we are the proud winners of the idex challenge for 
                                        </span>
                                        <span>
                                            developing sar satellite payloads for the indian air force
                                        </span>
                                    </p>
                                </DropInMotion>
                                <Box sx={awradSectionStyle}>
                                    <DropRightMotion>
                                        <img src={Certificate1} alt="Certificate 1" height={isMatchToTablet? 150 : isMatchToTablet2 ? 200 : 250}/>
                                    </DropRightMotion>
                                    <img src={IdexAwardReceiving} alt="Soumya Misra is Receiving the Idex Award" height={isMatchToTablet? 150 : isMatchToTablet2 ? 200 : 250}/>
                                    <DropLeftMotion>
                                        <img src={Certificate2} alt="Certificate 2" height={isMatchToTablet? 150 : isMatchToTablet2 ? 200 : 250}/>
                                    </DropLeftMotion>
                                </Box>
                                <DropOutMotion>
                                    <p
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            fontFamily: 'NunitoSans_7pt-BoldItalic',
                                            fontSize: '16px',
                                            // textTransform: 'capitalize',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <span>
                                            Sisir Radar CEO Soumya Misra receiving the
                                        </span>
                                        <span>
                                            award from Air Marshal Surat Singh of the IAF
                                        </span>
                                    </p>
                                </DropOutMotion>
                            </Box>
                        </>
                    )
                }
            </Box>
        </>
    )
}
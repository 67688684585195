import { 
    Box, 
    useMediaQuery,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

// import created modules -------------------------------------------------------
import { TextMotionOnWords, TextMotionOnCharacters } from '../utils/motioncomponents/TextMotion'
// import DropOutMotion from '../utils/motioncomponents/DropOutMotion'

// import images ----------------------------------------------------------------
import { 
    SARDemoProcessedImage,
    GPRDemoProcessedImage,
    HyspecDemoProcessedImage,
    NearFieldAms,
    FarFieldAms,
    BrochureFileIcon,

    // brochures
    FFHAMFInnoWave4,
    FFVAMFInnoWave4,
    NFAMFHexahedron
} from '../../assetsv1/Assets'

// function to get the vaertical scrolling zero ---------------------------------
const scrollToTop =() => {
    window.scrollTo(0,0)
}


// create a function for all tech solutions -------------------------------------
export default function AllThingsRadarInTechSolutions(){
    // variables ----------------------------------------------------------------
    // const theme & media-query 
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLgTablet = useMediaQuery('(max-width: 1350px)')
    const isMatchToLgTablet2 = useMediaQuery('(max-width: 1150px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')
    // const isMatchToMobileSm = useMediaQuery('(max-width: 480px)')

    // navigate-hook ------------------------------------------------------------
    const navigate = useNavigate()

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        width: '100vw',
        // flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#fff',
        pt: '8vh',
        // pb: '4vh',
        gap: '4vh',
        cursor: 'default'
    }
    // const headingStyle = {
    //     textAlign: 'center',
    //     fontFamily: 'Oxanium-Bold',
    //     fontSize: isMatchToMobileSm? '28px' : '40px',
    //     textTransform: 'uppercase',
    //     color: '#FF4F00',
    // }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        alignitems: 'center',
        justifyContent: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '64px' : '78px',
    }
    const child1Style = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: isMatchToTablet? '52px' : isMatchToLgTablet? '64px' : '48px',
    }
    const grndChildStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: isMatchToTablet? '70vw' : isMatchToLgTablet2? '50%' : '40%',
        justifyContent: 'space-between',
        fontSize: 'clamp(14px, 2vw, 16px)',
        color: '#232C60'
    }
    const knowMoreBtnStyle = {
        padding: '4px 8px 4px 8px',
        border: 'solid #232C60',
        backgroundColor: '#232C60',
        color: '#fff',
        mt: 'auto',
        fontFamily: 'NunitoSans_7pt-Bold',
        alignSelf: 'flex-start',
        cursor: 'pointer',
        marginLeft: '5px'
    }
    const brochureSectionContainerStyle = {
        display: 'flex',
        gap: '16px',
        flexFlow: 'row wrap',
        marginLeft: '5px'
    }
    // const brochureHeadingStyle = {
    //     // padding: '4px 8px 4px 8px',
    //     // border: 'solid #fff',
    //     fontFamily: 'NunitoSans_7pt-ExtraLight',
    //     fontSize: '18px',
    //     color: '#232C60'
    // }
    // const downloadBtnStyle = {
    //     padding: '4px 8px 4px 8px',
    //     border: 'solid #fff',
    //     fontFamily: 'NunitoSans_7pt-Bold',
    //     fontSize: '16px',
    //     color: '#232C60',
    //     cursor: 'pointer'
    // }
    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                {/* <DropOutMotion>
                    <p style={headingStyle}>all things radar</p>
                </DropOutMotion> */}
                <Box sx={flexSubContainerStyle}>
                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <img src={SARDemoProcessedImage} alt='SAR demo' style={{width: isMatchToLgTablet? '90%' : '80%', alignSelf: 'center' }}/>
                        </Box>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                {/* <TextMotionOnCharacters 
                                    text="SYNTHETIC APERTURE RADAR"
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px',
                                        width: '100%'
                                    }}
                                /> */}
                                <h1 
                                    style={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px',
                                        width: '100%'
                                    }}
                                >
                                    SYNTHETIC APERTURE RADAR
                                </h1>
                                <TextMotionOnWords
                                    text="Sisir Radar offers cutting-edge Synthetic Aperture Radar systems for air-borne and space-borne platforms operating in P/L/S/C/X bands. The payload can be optimised in terms of SWaP (Size, Weight and Power) - meaning that it can be fit in Tactical UAVs, VTOL and fixed-wing UAVs as well as aircrafts."
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'NunitoSans_7pt-Light', 
                                        fontSize: '16px',
                                        display: 'flex',
                                        flexFlow: 'wrap'
                                    }}
                                    delayTime={0}
                                />
                                <Box 
                                    sx={knowMoreBtnStyle}
                                    onMouseEnter = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onMouseLeave = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onTouchStart = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onTouchEnd = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onClick={() => {navigate('/technology/sar'); scrollToTop()}}
                                >
                                    Know More
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <img src={GPRDemoProcessedImage} alt='GPR Demo' style={{width: isMatchToLgTablet? '90%' : '80%', alignSelf: 'center' }}/>
                        </Box>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                {/* <TextMotionOnCharacters 
                                    text="GROUND PENETRATING RADAR"
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px'
                                    }}
                                /> */}
                                <h1 
                                    style={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px',
                                        width: '100%'
                                    }}
                                >
                                    GROUND PENETRATING RADAR
                                </h1>
                                <TextMotionOnWords
                                    text="Ruggedised Ground Penetrating Radars offered by Sisir Radar can be fitted on drones, carts, hand-held devices and other custom platforms. The GPRs have multiple applications like IED detection, mineral detection in underground mines, utility mapping and infrastructure monitoring."
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'NunitoSans_7pt-Light', 
                                        fontSize: '16px',
                                        display: 'flex',
                                        flexFlow: 'wrap'
                                    }}
                                    delayTime={0}
                                />
                                <Box 
                                    sx={knowMoreBtnStyle}
                                    onMouseEnter = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onMouseLeave = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onTouchStart = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onTouchEnd = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onClick={() => {navigate('/technology/gpr'); scrollToTop()}}
                                >
                                    Know More
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <img src={HyspecDemoProcessedImage} alt='Hyspec demo' style={{width: isMatchToLgTablet? '90%' : '80%', alignSelf: 'center' }}/>
                        </Box>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                {/* <TextMotionOnCharacters 
                                    text="HYPERSPECTRAL IMAGING"
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px'
                                    }}
                                /> */}
                                <h1 
                                    style={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px',
                                        width: '100%'
                                    }}
                                >
                                    HYPERSPECTRAL IMAGING
                                </h1>
                                <TextMotionOnWords
                                    text="Sisir Radar’s cutting-edge drone-borne Hyperspectral Imaging System makes it possible to collect hyperspectral data cube at a high speed using the Snapshot capture technology. The proprietary software provides user-friendly data cube visualisation capabilities and converts this data into actionable insights for applications in agriculture, environmental study, and urban planning."
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'NunitoSans_7pt-Light', 
                                        fontSize: '16px',
                                        display: 'flex',
                                        flexFlow: 'wrap'
                                    }}
                                    delayTime={0}
                                />
                                <Box 
                                    sx={knowMoreBtnStyle}
                                    onMouseEnter = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onMouseLeave = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onTouchStart = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onTouchEnd = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onClick={() => {navigate('/technology/hyspec');}}
                                >
                                    Know More
                                </Box>
                            </Box>
                        </Box>
                        
                    </Box>

                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <img src={FarFieldAms} alt='SAR demo' style={{width: isMatchToLgTablet? '90%' : '80%', alignSelf: 'center' }}/>
                        </Box>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                {/* <TextMotionOnCharacters 
                                    text="FAR-FIELD ANTENNA MEASUREMENT SYSTEM"
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px'
                                    }}
                                /> */}
                                <h1 
                                    style={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px',
                                        width: '100%'
                                    }}
                                >
                                    {/* corrected the spelling of measurement on 19Feb 2024 */}
                                    FAR-FIELD ANTENNA MEASUREMENT SYSTEM 
                                </h1>
                                <TextMotionOnWords
                                    text="Sisir Radar’s Far Field Antenna Measurement Facility is a practical tool that can improve and streamline the antenna testing process. With this technology, one can observe real-time radiation patterns using the proprietary application. The application provides detailed measurements of antenna radiation patterns and realised gain over the full frequency range."
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'NunitoSans_7pt-Light', 
                                        fontSize: '16px',
                                        display: 'flex',
                                        flexFlow: 'wrap'
                                    }}
                                    delayTime={0}
                                />
                                <Box sx={brochureSectionContainerStyle}>
                                    <Box 
                                        style={{
                                            margin: '0px 0px',
                                            display: 'flex', 
                                            flexShrink: '0', 
                                            gap: '6px', 
                                            height: '14px', 
                                            alignItems: 'center', 
                                            justifyContent: 'center',
                                            cursor: 'default'
                                        }}
                                    >
                                        <img 
                                            src={BrochureFileIcon} 
                                            alt='brochure-1' 
                                            style={{height: '100%'}}
                                        />
                                        <p 
                                            style={{
                                                // height: '100%', 
                                                fontSize: '14px', 
                                                fontFamily: 'NunitoSans_7pt-Regular'
                                            }}
                                            onMouseEnter = {(event) => {
                                                event.target.style.textDecoration = "underline"
                                            }}
                                            onMouseLeave = {(event) => {
                                                event.target.style.textDecoration = "none"
                                            }}
                                            onTouchStart = {(event) => {
                                                event.target.style.textDecoration = "underline"
                                            }}
                                            onTouchEnd = {(event) => {
                                                event.target.style.textDecoration = "none"
                                            }}
                                            onClick={() => {window.open(FFVAMFInnoWave4, '_blank')}}
                                        >
                                            {/* FF-VAMF-InnoWave 4 */}
                                            VFF-AMF-InnoWave 4
                                        </p>
                                    </Box>
                                    <Box 
                                        style={{
                                            display: 'flex', 
                                            flexShrink: '0', 
                                            gap: '6px', 
                                            height: '14px', 
                                            alignItems: 'center', 
                                            justifyContent: 'center',
                                            cursor: 'default',
                                        }}
                                    >
                                        <img 
                                            src={BrochureFileIcon} 
                                            alt='brochure-1' 
                                            style={{height: '100%'}}
                                        />
                                        <p 
                                            style={{
                                                // height: '100%', 
                                                fontSize: '14px', 
                                                fontFamily: 'NunitoSans_7pt-Regular'
                                            }}
                                            onMouseEnter = {(event) => {
                                                event.target.style.textDecoration = "underline"
                                            }}
                                            onMouseLeave = {(event) => {
                                                event.target.style.textDecoration = "none"
                                            }}
                                            onTouchStart = {(event) => {
                                                event.target.style.textDecoration = "underline"
                                            }}
                                            onTouchEnd = {(event) => {
                                                event.target.style.textDecoration = "none"
                                            }}
                                            onClick={() => {window.open(FFHAMFInnoWave4, '_blank')}}
                                        >
                                            HFF-AMF-InnoWave 4
                                            {/* FF-HAMF-InnoWave 4 */}
                                        </p>
                                    </Box>
                                </Box>

                                {/* <Box 
                                    sx={knowMoreBtnStyle}
                                    onMouseEnter = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onMouseLeave = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onTouchStart = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onTouchEnd = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onClick={() => {}}
                                >
                                    Download Brochure
                                </Box> */}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <img src={NearFieldAms} alt='GPR Demo' style={{width: isMatchToLgTablet? '90%' : '80%', alignSelf: 'center' }}/>
                        </Box>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                <h1 
                                    style={{
                                        position: 'relative',
                                        fontFamily: 'Oxanium-Bold', 
                                        fontSize: '18px',
                                        display: 'flex',
                                        marginLeft: '5px',
                                        width: '100%'
                                    }}
                                >
                                    {/* corrected the spelling of measurement on 19Feb 2024 */}
                                    NEAR-FIELD ANTENNA MEASUREMENT SYSTEM
                                </h1>
                                <TextMotionOnWords
                                    text="Sisir Radar’s Near Field Antenna Measurement System offers fully automated high-precision scanning technology. This anechoic chamber provides a seamless and fast software-controlled scanning system with the lowest interval of 5 mm spacing."
                                    containerStyle={{
                                        position: 'relative',
                                        fontFamily: 'NunitoSans_7pt-Light', 
                                        fontSize: '16px',
                                        display: 'flex',
                                        flexFlow: 'wrap'
                                    }}
                                    delayTime={0}
                                />
                                <Box sx={brochureSectionContainerStyle}>
                                    <Box 
                                        style={{
                                            margin: '0px 0px',
                                            display: 'flex', 
                                            flexShrink: '0', 
                                            gap: '6px', 
                                            height: '14px', 
                                            alignItems: 'center', 
                                            justifyContent: 'center',
                                            cursor: 'default'
                                        }}
                                    >
                                        <img 
                                            src={BrochureFileIcon} 
                                            alt='brochure-1' 
                                            style={{height: '100%'}}
                                        />
                                        <p 
                                            style={{
                                                // height: '100%', 
                                                fontSize: '14px', 
                                                fontFamily: 'NunitoSans_7pt-Regular'
                                            }}
                                            onMouseEnter = {(event) => {
                                                event.target.style.textDecoration = "underline"
                                            }}
                                            onMouseLeave = {(event) => {
                                                event.target.style.textDecoration = "none"
                                            }}
                                            onTouchStart = {(event) => {
                                                event.target.style.textDecoration = "underline"
                                            }}
                                            onTouchEnd = {(event) => {
                                                event.target.style.textDecoration = "none"
                                            }}
                                            onClick={() => {window.open(NFAMFHexahedron, '_blank')}}
                                        >
                                            NF-AMF-Hexahedron
                                        </p>
                                    </Box>
                                </Box>
                                {/* <Box 
                                    sx={knowMoreBtnStyle}
                                    onMouseEnter = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onMouseLeave = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onTouchStart = {(event) => {
                                        event.target.style.backgroundColor = "transparent"
                                        event.target.style.color = "#232C60"
                                    }}
                                    onTouchEnd = {(event) => {
                                        event.target.style.backgroundColor = "#232C60"
                                        event.target.style.color = "#fff"
                                    }}
                                    onClick={() => {}}
                                >
                                    Download Brochure
                                </Box> */}
                            </Box>
                        </Box>
                    </Box>
 
                </Box>
            </Box>
        </>
    )
}
// import node-modules ----------------------------------------------------------
import React, { useRef, useEffect } from "react"
import { motion, useAnimation, useInView } from "framer-motion"

// create a function that animates a text on the basis of words -----------------
export function TextMotionOnWords({text, containerStyle, delayTime}){

    // variables ----------------------------------------------------------------
    // create an array of words in the text
    const words = text.split(" ")
    const ref = useRef(null)
    const isInView = useInView(ref, {once: true})
    const animationControls = useAnimation()

    // fire animation once if it is in view -------------------------------------
    useEffect(()=>{
        if(isInView){
            animationControls.start("visible")
        }
    }, [isInView])

    // main-layout --------------------------------------------------------------
    return(
        <>
            <div
                ref={ref}
                style={containerStyle}
            >
                {
                    words.map((item, index)=>(
                        <>
                            <motion.div
                                style={{marginLeft: '5px'}}
                                variants={{
                                    hidden: {opacity: 0, y: "20"},
                                    visible: {opacity: 1, y: 0}
                                }}
                                initial="hidden"
                                animate={animationControls}
                                transition={{
                                    duration: 0.5,
                                    delay: (index * 0.010 + (delayTime? delayTime : 0)),
                                    ease: "easeIn",
                                    type: "spring",
                                    damping: "100",
                                    stiffness: "500"
                                }}
                            >
                                {item}
                            </motion.div>
                        </>
                    ))
                }
            </div>
        </>
    )
}

export function TextMotionOnCharacters({text, containerStyle, delayTime}){
    // variables ----------------------------------------------------------------
    // create an array of words in the text
    const characters = Array.from(text)
    const ref = useRef(null)
    const isInView = useInView(ref, {once: true})
    const animationControls = useAnimation()

    // fire animation once if it is in view -------------------------------------
    useEffect(()=>{
        if(isInView){
            animationControls.start("visible")
        }
    }, [isInView])

    // main-layout --------------------------------------------------------------
    return(
        <>
            <div
                ref={ref}
                style={containerStyle}
            >
                {
                    characters.map((item, index)=>(
                            <motion.div
                                key={index}
                                variants={{
                                    hidden: {opacity: 0, y: 20},
                                    visible: {opacity: 1, y: 0}
                                }}
                                initial="hidden"
                                animate={animationControls}
                                transition={{
                                    staggerChildren: 0.1,
                                    duration: 2,
                                    delay: (index * 0.04) + (delayTime ? delayTime : 0),
                                    ease: "easeIn",
                                    type: "spring",
                                    damping: "100",
                                    stiffness: "500"
                                }}
                            >
                                {item === " "? "\u00A0" : item}
                            </motion.div>
                    ))
                }
            </div>
        </>
    )
}
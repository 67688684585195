// import node-modules ----------------------------------------------------------
import React from 'react'
import { Helmet } from 'react-helmet-async'

// import created modules -------------------------------------------------------
import Banner from '../aboutuscomponents/AboutUsBanner'
import CompanyGoal from '../aboutuscomponents/ComapanyGoal'
import Tribute2Legend from '../aboutuscomponents/Tribute2LegendNew'
// import Tribute2Legend from '../aboutuscomponents/Tribute2Legend'
import Founders from '../aboutuscomponents/Founders'
import Advisors from '../aboutuscomponents/Advisors'
// import OurTimeLine from '../aboutuscomponents/timeline/TimeLine'
import OurTimeLine from '../aboutuscomponents/timeline/TimeLineNew'

// import images ----------------------------------------------------------------
import { FavIcon } from '../../assetsv1/Assets'

// create the about us page -----------------------------------------------------
export default function AboutUs(){

    // main layout --------------------------------------------------------------
    return(
        <>
            <Helmet>
                <title>About Us | Sisir Radar</title>
                <meta name='description'content='Peek into the Vision and Mission of Sisir Radar for a more Aatmanirbhar Bharat, led by our team of expert scientists.' data-rh='true'/>
                <link rel='cannonical' href='/aboutus'/>
                <link rel="icon" href={FavIcon} />
            </Helmet>
            <Banner/>
            <CompanyGoal/>
            <OurTimeLine/>
            <Tribute2Legend />
            <Founders/>
            <Advisors/>
            {/* <Banner />
            <CompanyGoal />
            <Tribute2Legend />
            <Founders />
            <Advisors /> */}
        </>
    )
}
// import node modules ----------------------------------------------------------
import React, { useRef, useEffect } from "react"
import { motion, useAnimation, useInView } from "framer-motion"


// This function will drop the children element in towards left ----------------
export default function DropLeftMotion({children, delayTime}){

    // variables ----------------------------------------------------------------
    const ref = useRef(null)
    const isInView = useInView(ref, {once: true})
    const animationControls = useAnimation()

    // fire animation once if it is in view -------------------------------------
    useEffect(()=>{
        if(isInView){
            animationControls.start("visible")
        }
    }, [isInView])

    // main layout --------------------------------------------------------------
    return(
        <>
        <div
            ref={ref}
            style={{position: "relative", overflow: "hidden"}}
        >
            <motion.div
                variants={{
                    hidden: {opacity: 0, x: "200vw"},
                    visible: {opacity: 1, x: 0}
                }}
                initial="hidden"
                animate={animationControls}
                transition={{
                    duration: 2,
                    delay: delayTime ? delayTime : 0,
                    ease: "easeIn",
                    type: "spring",
                    damping: "100",
                    stiffness: "500"
                }}
            >
                {children}
            </motion.div>
        </div>
        </>
    )
}
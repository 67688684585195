// import created modules -------------------------------------------------------
import {
    Box,
    useMediaQuery,
} from '@mui/material'
// import { useMotionValue, useTransform, motion } from 'framer-motion'

// import images ----------------------------------------------------------------
// import { 
//     SARData, 
// } from '../../assets/Assets'
import { WhatIsGPRBgImageLg, GPRDemoProcessedImage } from '../../assetsv1/Assets'

// SAR description --------------------------------------------------------------
const GPRDescription = [
    'Ground Penetrating Radar (GPR) is a geophysical technique that uses radar pulses to create 2D and 3D images of subsurface features. GPR images show change in reflectance between 2 surfaces. This is used to deduce information about subsurface structural variation and buried objects.',
    'GPR is non-invasive and non-destructive, making it a safe and cost-effective solution for a variety of applications, such as locating utilities, detecting archaeological artefacts, and assessing the condition of pavement and concrete structures. Its high resolution and accuracy make it an essential tool for researchers, engineers, and archaeologists alike.',
]

// create definition-of-sar component -------------------------------------------
export default function WhatIsGPR(){

    // variables ----------------------------------------------------------------
    // const theme & media-query 
    const isMatchToLgTabletSm = useMediaQuery('(max-width: 760px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLgTablet = useMediaQuery('(max-width: 1350px)')
    const isMatchToLgTablet2 = useMediaQuery('(max-width: 1150px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')
    const isMatchToMobileSm = useMediaQuery('(max-width: 480px)')

    // motion variables
    // const x = useMotionValue(0)
    // const y = useMotionValue(0)
    // const rotateX = useTransform(y, [-100, 100], [-50, 50])
    // const rotateY = useTransform(x, [100, -100], [50, -50])

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        width: '100vw',
        color: '#fff',
        fontFamily: 'prompt',
        justifyContent: 'center',
        // backgroundColor: '#232C60'
        background: `url(${WhatIsGPRBgImageLg})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        alignitems: 'center',
        justifyContent: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '64px' : '78px',
    }
    const child1Style = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: isMatchToTablet? '52px' : isMatchToLgTablet? '64px' : '48px',
    }
    const grndChildStyle = {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: isMatchToTablet? '70vw' : isMatchToLgTablet2? '50%' : '40%',
        justifyContent: 'space-between',
        fontSize: 'clamp(14px, 2vw, 16px)'
    }
    const headerTextContainerStyle = {
        display: 'flex',
        color: '#fff',
        maxWidth: isMatchToMobileSm ? '90%' : '80%',
        alignItems: 'center',
        textTransform: 'uppercase',
        fontFamily: 'Oxanium-Bold',
        mb: '16px',
    }
    const descriptionTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        maxWidth: '700px',
        // minWidth: '300px',
        alignItems: 'flex-start',
        fontSize: isMatchToLgTabletSm ? '12px' : '16px',
        fontFamily: 'NunitoSans_7pt-Light',
        width: isMatchToMobileSm? '90%' : isMatchToLgTablet? '100%' :  '80%'
    }

    const headingStyle = {
        // marginLeft: '20px',
        width: '100%',
        textAlign: isMatchToMobileSm ? 'center' : '',
        fontFamily: 'Oxanium-Bold',
        // fontSize: isMatchToMobileSm? '28px' : '40px',
        textTransform: 'uppercase',
        color: '#fff',
        fontSize: isMatchToMobileSm? '18px' : '24px',
        
        // fontSize: 'clamp(24px, 3vw, 40px)',
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={child1Style}>
                        <Box sx={grndChildStyle}>
                            <Box 
                                sx={{ 
                                    width: isMatchToMobileSm? '90%' : isMatchToLgTablet? '100%' :  '80%', 
                                    gap: '20px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignSelf: 'center'
                                }}
                            >
                                <Box sx={headerTextContainerStyle}>
                                    <p style={headingStyle}>what is Ground Penetrating Radar?</p>
                                </Box>
                                <Box sx={descriptionTextContainerStyle}>
                                    <p className='gpr-description' style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                        {GPRDescription.map((item, index)=>(
                                            <span key={index}>{item}</span>
                                        ))}
                                    </p>
                                </Box> 
                            </Box>   
                        </Box>
                        <Box style={grndChildStyle}>
                            <img src={GPRDemoProcessedImage} alt='gpr payload' style={{width: isMatchToLgTablet? '90%' : '70%', alignSelf: 'center' }}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
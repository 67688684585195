// import created modules -------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import created modules -------------------------------------------------------
import CustomIconCard from '../sarcomponents/CustomIconCard'

// import images ----------------------------------------------------------------
// import { 
//     Resolution0Dot3m,
//     HybridPolarimetryAndInterferometry,
//     ThreeX,
//     Km25,
//     Sqkm100,
//  } from '../../assets/Assets'
import {
    SpatialResolution,
    SpectralResolution,
    SpectralBands,
    SpectralRange,
    ImagingArea
} from '../../assetsv1/Assets'

// list-of-icons ----------------------------------------------------------------
const otherIconList = [
    {
        icon: SpatialResolution,
        description: ['Spatial', 'Resolution'],
        subDescription: ['(at 20 m altitude)']
    },
    {
        icon: SpectralResolution,
        description: ['Spectral', 'Resolution']
    },
    {
        icon: SpectralBands,
        description: ['Spectral', 'Bands']
    },
    {
        icon: SpectralRange,
        description: ['Spectral', 'Range']
    },
    {
        icon: ImagingArea,
        description: ['Imaging Area', 'Per Frame'],
        subDescription: ['(at 20 m altitude)']
    }
]

// create feature-component of HySpec ----------------------------------------------
export default function FeaturesOfHySpec(){

    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    // const isMatchToLgDevice = useMediaQuery(theme.breakpoints.between('lg', 'xl'))
    const isMatchToMdDevice = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToMobileSm = useMediaQuery('(max-width: 450px)')
    const isMatchToMobile = useMediaQuery('(max-width: 640px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        backgroundColor: '#fff',
        width: '100vw',
        height: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
    }
    const flexSubContainerStyle = {
        display: 'flex', 
        flexDirection: 'column',
        width: '95%', 
        height: 'auto', 
        justifyContent: 'space-around',
        alignItems: 'center',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '52px' : '48px',
    }
    const headerTextContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        color: '#232C60',
        maxWidth: '80%',
        alignItems: 'center',
        textTransform: 'uppercase',
        textAlign: 'center'
    }
    const mainElementContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        color: '#232C60',
        maxWidth: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '40px',
    }
    const cardContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexFlow: 'row wrap',
        width: 'fit-content'
    }
    const childStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexFlow: 'row wrap',
    }

    // main layout --------------------------------------------------------------
    return(
        <>
            <Box sx={flexMainContainerStyle}>
                <Box sx={flexSubContainerStyle}>
                    <Box sx={headerTextContainerStyle}>
                        <p style={{fontFamily:'Oxanium-Bold', textTransform: 'uppercase', fontSize:  isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px',}}>
                            Features of Drone-borne Hyperspectral Imaging
                        </p>
                    </Box>
                    <Box sx={mainElementContainerStyle}>
                        <Box sx={cardContainerStyle}>
                            {
                                isMatchToMobile ? (
                                    <>
                                        {
                                            otherIconList.map((icon, index)=>(
                                                < CustomIconCard 
                                                    key={index}
                                                    icon={icon.icon}
                                                    description={icon.description}
                                                    subDescription={icon.subDescription? icon.subDescription : ''}
                                                    iconWidth='96px'
                                                    cardWidth= {isMatchToMobileSm ? '130px' : '155px'}
                                                    fontSize={isMatchToMobileSm? '14px' : '16px'}
                                                    subFontSize={isMatchToMobileSm? '10px' : '12px'}
                                                    textTransform={'none'}
                                                />
                                            ))
                                        }
                                    </>

                                ):(
                                    <>
                                        <Box sx={childStyle}>
                                            {
                                                otherIconList.slice(0,3).map((icon, index)=>(
                                                    < CustomIconCard 
                                                        key={index}
                                                        icon={icon.icon}
                                                        description={icon.description}
                                                        subDescription={icon.subDescription? icon.subDescription : ''}
                                                        iconWidth={isMatchToMdDevice? '96px'  : '96px'}
                                                        fontSize='16px'
                                                        subFontSize={'14px'}
                                                        cardWidth='155px'
                                                        textTransform={'none'}
                                                    />
                                                ))
                                            }
                                        </Box>
                                        <Box sx={childStyle}>
                                            {
                                                otherIconList.slice(3, 5).map((icon, index)=>(
                                                    < CustomIconCard 
                                                        key={index}
                                                        icon={icon.icon}
                                                        description={icon.description}
                                                        subDescription={icon.subDescription? icon.subDescription : ''}
                                                        iconWidth={isMatchToMdDevice? '96px'  : '96px'}
                                                        fontSize='16px'
                                                        subFontSize={'14px'}
                                                        cardWidth='155px'
                                                        textTransform={'none'}
                                                    />
                                                ))
                                            }
                                        </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
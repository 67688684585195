// import node modules ----------------------------------------------------------
import { React, useState } from "react"
import { Outlet } from "react-router-dom"
import { Box, useTheme, useMediaQuery } from '@mui/material'

// import created modules -------------------------------------------------------
import Header from "../header/Header"
import Footer from "../footer/Footer"
// import DropInMotion from "../utils/motioncomponents/DropInMotion"

// import gifs ------------------------------------------------------------------
import { PreloaderGifDelay200Ms as PreloaderGif} from '../../assetsv1/Assets'
// import SisirLogoGifSvg from "../../assetsv1/gifs/SisirDotsLogoGifSvg"

// main layout ------------------------------------------------------------------
export default function MainLayout () {

    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))

    // state variables ----------------------------------------------------------
    const [loading, setLoading] = useState(true)

    // functions ----------------------------------------------------------------
    function loadingFunction(){
        setTimeout(()=>{
            setLoading(false)
        }, 1500)
    }
    // style --------------------------------------------------------------------
    const preloaderStyle = {
        background: '#fff',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        zIndex: '9999',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'promptSemiBold'
    }

    // main layout --------------------------------------------------------------
    return(
        <> 
            <div onLoad={()=>loadingFunction()}>                                
                {
                    loading ?(
                    <>
                        <Box className='preloader' sx={preloaderStyle}> 
                            <span style={{color: '232C60', textTransform: 'capitalize', fontSize: isMatchToSmallDevice? '18px' : '24px'}}>
                                loading...
                            </span>
                            {/* <PreLoader/> */}
                            <img src={PreloaderGif} alt='please wait' height={isMatchToSmallDevice? 64 : 72}/>

                             {/* svg animation trial - not working till now */}
                            {/* <div style={{height: isMatchToSmallDevice? '64px' : '72px'}}>
                                <SisirLogoGifSvg/>
                            </div> */}
                        </Box>
                    </> 
                    ):(
                        <>
                            <Header />
                            <Outlet />
                            <Footer />
                        </>
                    )
                } 
            </div>
        </>
    )
}
// import node-modules ----------------------------------------------------------
import React from 'react'
import {Helmet} from 'react-helmet-async'

// import created modules -------------------------------------------------------
// import SARApplication from "../homecomponents/SARApplications"
// import DroneBorneSAR from "../technologycomponents/DroneBorneSAR"
// import FeatureOfSAR from "../technologycomponents/FeaturesOfSAR"
// import SARRangeAndCoverage from "../technologycomponents/SARRangeCoverage"
// import SARSuperiority from "../technologycomponents/SARSuperiority"
import Banner from "../technologycomponents/TechBanner"
import AllThingsRadarInTechSolutions from '../technologycomponents/AllThingsRadar'
// import OtherProductsInTechSolutions from '../technologycomponents/OtherProducts'
// import WhatIsSAR from "../technologycomponents/WhatIsSAR"

// import images ----------------------------------------------------------------
import { FavIcon } from '../../assetsv1/Assets'

// create tech-page -------------------------------------------------------------
export default function Technology(){

    // main layout --------------------------------------------------------------
    return(
        <>
            <Helmet>
                <title>Technology | Sisir Radar</title>
                <meta name='description'content='Get a better understanding of SAR and its various military and civilian applications.' data-rh='true'/>
                <link rel='cannonical' href='/technology'/>
                <link rel="icon" href={FavIcon} />
            </Helmet>
            <Banner/>
            <AllThingsRadarInTechSolutions/>
            {/* <OtherProductsInTechSolutions/> */}
            {/* <Banner/>
            <WhatIsSAR/>
            <DroneBorneSAR/>
            <FeatureOfSAR />
            <SARSuperiority />
            <SARRangeAndCoverage/>
            <SARApplication 
                mainHeadText={'APPLICATIONS'} 
                backgroundColor={'#232C60'} 
                textColor={'#fff'} 
                mainContentBackgroundColor={'#fff'} 
                mainContentTextColor={'#232C60'} 
            /> */}
        </>
    )
}
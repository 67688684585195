// import node-modules ----------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import created modules -------------------------------------------------------
import DropRightMotion from '../utils/motioncomponents/DropRightMotion'

// import images ----------------------------------------------------------------
// import {
//     TechnologyHeaderImageLg2,
//     TechnologyHeaderImageMd2,
//     TechnologyHeaderImageSm2
// } from '../../assets/Assets'
import { 
    TechnologyHeaderImageLg,
    TechnologyHeaderImageMd,
    TechnologyHeaderImageSm,
    TechHeaderTitleText 
} from '../../assetsv1/Assets'

// create the hero-banner section of about-us page ------------------------------
export default function Banner(){
    
    //  constant theme and media-query ------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToLaptop = useMediaQuery('(max-width: 960px) and (orientation: portrait)')
    // const isMatchToTablet = useMediaQuery('(max-width: 760px) and (orientation: portrait)')
    // const isMatchToMobile = useMediaQuery('(max-width: 415px) and (orientation: portrait)')
    // const isMatchToMobileLg = useMediaQuery('(max-width: 580px) and (orientation: portrait)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        position: 'relative', 
        display: 'flex', 
        width: '100vw', 
        height: 'auto', 
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    }
    const textContainerStyle = {
        position: 'absolute',
        display:'flex', 
        flexDirection: 'column',
        color: '#fff', 
        width: isMatchToLaptop? '50%' :'40%', 
        height: '100%',
        alignItems: 'flex-start',
        pl: '5vw',
        pt: '40vh',
        // justifyContent: 'center',
        // fontFamily: 'prompt',
        // pl: '10vw',
        // background: 'linear-gradient(to right, #232C60, transparent)',
    }

    // main layout --------------------------------------------------------------
    return(
        <>  
            <Box sx={flexMainContainerStyle}>
                <img 
                    src={isMatchToSmallDevice? TechnologyHeaderImageSm : isMatchToLaptop? TechnologyHeaderImageMd : TechnologyHeaderImageLg} 
                    // src={SARHeaderImageLg}
                    alt='technology' 
                    style={{width: '100vw', height:'100vh'}}
                />
                <Box sx={textContainerStyle}> 
                    <DropRightMotion delayTime={0.5}>
                        <img 
                            src={TechHeaderTitleText}
                            alt='Technology solutions'
                            style={{width: isMatchToSmallDevice ? '70vw' : isMatchToLaptop ? '60vw' : '40vw'}}
                        />
                    </DropRightMotion>
                    </Box>
            </Box>
        </>
    )
}